.supportingInstitutions {
    padding: 0;
    margin: 0 auto 3.375rem;
    text-align: center;
}
.supportingInstitutions h4 {
    margin-bottom: -0.625rem;
}
.supportingInstitutions h4 {
    margin-bottom: 3.75rem;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3.125rem;
}
.supportingInstitutions-list {
    /* display: flex;
    max-width: 100%;
    overflow-x: auto; */
    /* margin: 0 auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.supportingInstitutions-list>a {
    /* margin-top: 2.375rem;
    margin-left: 2.1875rem;
    margin-right:1.1875rem; */
    margin: 6px;
}
.marquee{
 min-width: 0% !important;
}
.item{
    background-color:  rgb(255 255 255 / 8%);
}
.supportingInstitutions-list .item img {
    display: block;
    width: 3.375rem;
    height: 3.375rem;
    transition: all .3s;
    -webkit-transform: translateY(0.25rem);
    transform: translateY(0.25rem);
}

.supportingInstitutions-list .item .text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    transition: all .3s;
    -webkit-transform: translateY(2.1875rem);
    transform: translateY(1rem);
    opacity: 0;
}
.supportingInstitutions-list .item span{
    display: block;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: .02em;
    -webkit-transform: scale(.78);
    transform: scale(.78);
    font-size: .75rem;
    color: #fff;
    text-transform: capitalize;
}
.supportingInstitutions-list .item{
    width: 5.625rem;
    height: 5.625rem;
    border: 0.08761rem solid hsla(0,0%,100%,.1);
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all .3s;
    cursor: pointer;
    overflow: hidden;
}
.supportingInstitutions-list .item:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    border: 0.06875rem solid hsla(0,0%,100%,.5);
}
.ecology-component .ecology-header .filter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}
.overlay::before, .overlay::after {
    background: none !important;
}
@media only screen and (max-width: 768px) {
   .supportingInstitutions {
        padding: 0;
    }
  }
  @media only screen and (max-width: 768px) {
  .supportingInstitutions h4 {
        margin-bottom: 0.125rem;
    }
   }
   @media only screen and (max-width: 768px) {
    .supportingInstitutions-list {
        width: 100%;
        /* padding: 0 1.25rem; */
        justify-content: space-between;
    }
   }
   @media only screen and (max-width: 768px) {
    .supportingInstitutions-list>a {
        /* margin-top: 1.3125rem; */
        margin-right: 0;
        margin-left: 0;
    }
    .supportingInstitutions-list>a {
        /* margin-top: 2.375rem;
        margin-left: 2.1875rem;
        margin-right:1.1875rem; */
        margin: 0px !important;
    }
   }
   @media only screen and (max-width: 768px) {
    .supportingInstitutions-list .item {
        margin: 5px;
        width: 5.375rem;
        height: 5.375rem;
        border-radius: 0.625rem;
    }
     }
     @media only screen and (max-width: 768px) {
        .supportingInstitutions-list .item img {
            width: 1.625rem;
            height: 1.625rem;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
     }
     @media only screen and (max-width: 768px) {
        .supportingInstitutions-list .item .text-wrapper {
            height: 1rem;
            margin-top: 0.625rem;
            transition: all .3s;
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
        }
     }
     @media only screen and (max-width: 768px) {
        .supportingInstitutions-list .item span {
            font-size: .75rem;
            line-height: 1rem;
        }
     }
     @media only screen and (max-width: 768px) {
        .supportingInstitutions-list .item .text-wrapper {
            height: 1rem;
            margin-top: 0.625rem;
            transition: all .3s;
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
        }
     }
     @media only screen and (max-width: 768px) {
        .coin-name {
           font-size: 2px;
        }
     }