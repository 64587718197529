.member-img{
    /* height:140px;*/
    width:200px;
    /* height: 50px;
    width: 128px; */
    -o-object-fit: contain;
    object-fit: contain;
}
@media only screen and (max-width: 768px) {
    .member-img {
        height:70px;
        width:150px;
        -o-object-fit: contain;
        object-fit: contain;
    }
  }
  /* .member-container{
   margin-left: 90px;
  } */
  @media only screen and (max-width: 768px) {
    .member-container{
        margin-left: 0px;
       }
  }

  .footer a {
    text-decoration: none;
    color:rgb(131, 131, 131);
    /* font-size: 12px; */
    
  }
  .footer a:hover {
    color:rgb(255, 255, 255) !important;
  }

  .img-card-member { 
    display: flex;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0);
    /* border: 1px solid rgb(18, 20, 28); */
    border-radius: 0.5rem;
    min-height: 6rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    transition: all .5s ease;
  }
  .img-card-member:hover {   
    background-color: rgb(225 225 225 / 7%);
    /* border: 1px solid rgb(22, 25, 44);; */
  }