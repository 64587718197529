.tron-usdd-wrapper.bg_psm {
  min-height: 100vh;
  background-image: url("../img/psm_bg.de563421.jpg");
  background-attachment: fixed;
  background-size: 100% 100%;
  overflow: hidden;
}
.disconnect-style{
  font-size: 0.875rem;
  color: hsla(0, 0%, 100%, 0.6);
}
.disconnect-style:hover{
  color: rgba(255, 255, 255, 0.603) !important;
  border: 1px solid rgba(255, 255, 255, 0.603);
}
.table{
  color:white !important;
}
.bg-head-color{
  background: #284e6a38;
}
.bg-table-color{
  background: #5d829d4f;
}
.MuiPaginationItem-root {
  color: rgba(255, 255, 255, 0.87) !important;
}

.psm-swap-container .psm-descriptions {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 0.9375rem;
  background: linear-gradient( 55deg, rgba(81, 155, 242, 0.2), #181520 30%, rgba(24, 21, 32, 0) 104.24% );
  padding: 2.5rem 0.625rem 0 2.5rem;
  text-align: start;
  white-space: nowrap;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  color: #fff;
   /* width: 43.25rem;  */
}

.input-side{
  /* border: 1px solid #6e7180; */
  padding: 7px;
  border-radius: 4px;
}
@media only screen and (max-width:400px) {
  .input-side {
    padding: 4px !important;
}
}
.login-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}
.login-wrapper .back-menu {
  background: hsla(0, 0%, 100%, 0.1);
  -webkit-backdrop-filter: blur(0.875rem);
  backdrop-filter: blur(0.875rem);
  border-radius: 0.625rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: hsla(0, 0%, 100%, 0.7);
  border: none;
  padding: 10px;
}

.login-wrapper .back-menu .back-icon {
  display: inline-block;
  width: 0.9375rem;
}
.psm-swap-container .link-wallet {
  padding: 0 1.9375rem;
  background-image: linear-gradient(90deg, #58e1d9 17.4%, #a879fb 89.41%);
  border-radius: 0.625rem;
  text-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 2.125rem;
  letter-spacing: 0.02em;
  color: #fff;
  cursor: pointer;
}

.psm-swap-container .psm-swap-cont {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  /* padding-bottom: 3.125rem; */
  background-image: linear-gradient(180deg, #201f2b, rgba(32, 31, 43, 0.3)),
    url("../img/form_bg.e3b3fc2e.png");
  background-repeat: no-repeat;
  background-position: 0 0, 0 bottom;
  border-radius: 10px;
}
.psm-swap-container .psm-description {
  position: relative;
  /* margin-top: 2.5rem; */
  margin-bottom: 0.9375rem;
  /* background: linear-gradient(
    55deg,
    rgba(81, 155, 242, 0.2),
    #181520 30%,
    rgba(24, 21, 32, 0) 104.24% 
  ); */
  padding: 2.5rem 0.625rem 0 2.5rem;
  text-align: start;
  white-space: nowrap;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  color: #fff;
  width: 43.25rem;
  border-right: 1px solid #2c2828;
}
/* .psm-swap-container .psm-description:before {
  content: "";
  display: block;
  width: 8.595rem;
  height: 9.53938rem;
  position: absolute;  
  top: 0.175rem;
  right: 1.1925rem;
  background-image: url("../img/startUseLogo.cb0385d5.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
} */
.psm-swap-container .psm-description .des-content-title {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.625rem;
  margin-bottom: 1.5rem;
}
.psm-swap-container .psm-description .des-content {
  position: relative;
  min-height: 30rem;
}

.psm-swap-container .psm-description .des-content > div > div:first-child {
  margin-top: 0;
}
.psm-swap-container .psm-description .des-content .des-item-title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 1.8rem;
  white-space: pre-wrap;
}
.psm-swap-container .psm-description .des-content .des-item-content {
  padding-right: 4.6875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 142.85%;
  margin-top: 0.625rem;
  opacity: 0.6;
  white-space: pre-wrap;
}
.psm-swap-container .psm-description .des-content .des-item-title:nth-child(3) {
  margin-top: 2.625rem;
}
.psm-swap-container .psm-description .des-content .des-item-content {
  padding-right: 4.6875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 142.85%;
  margin-top: 0.625rem;
  opacity: 0.6;
  white-space: pre-wrap;
}
.psm-swap-container .psm-description .des-content > div > div:last-child {
  padding-bottom: 1.875rem;
}
.psm-swap-container .psm-description .des-content .address-enter-btn {
  display: inline-block;
  line-height: 2.425rem !important;
  padding: 0 2rem;
  margin-right: 0.625rem;
  font-weight: normal;
  font-size: 1rem;
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
}
.button-style1 {
  background: hsla(0, 0%, 100%, 0.1);
  border: 0.0625rem solid hsla(0, 0%, 100%, 0.3);
}
a {
  color: #fff;
  text-decoration: none;
}

.psm-swap-container .psm-swap {
  width: 37rem;
  /* background-image: linear-gradient(180deg, #201f2b, rgba(32, 31, 43, 0.3)),
    url("../img/form_bg.e3b3fc2e.png");
  background-repeat: no-repeat;
  background-position: 0 0, 0 bottom;
  background-size: auto, 100% auto; */
  border-top: 0.09375remsolidhsla (0, 0%, 100%, 0.2);
  padding: 2.5rem 0.625rem 0 2.5rem;
  text-align: center;
  overflow-wrap: break-word;
  display: inline-block;
  margin: 0;
  position: relative;
  z-index: 0;
  color: #fff;
  border-radius: 1.25rem;
  right: 15px;
}
.psm-swap-container .psm-swap .psm-swap-title {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.psm-swap-container .psm-swap .psm-swap-title .psm-swap-title-text {
  
  font-weight: 600;
  font-size: 2rem;
  /* line-height: 3.5rem; */
}
.psm-swap-container .psm-swap .psm-swap-amount-detail {
  background: hsla(0, 0%, 100%, 0.1);
  border: 0.0625rem solid hsla(0, 0%, 100%, 0.3);
  border-radius: 0.5rem;
  padding: 0.375rem 1rem;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 600;
  color: #82ffe1;
  cursor: pointer;
  display: none !important;
}
.psm-swap-container .psm-swap .psm-swap-des {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  opacity: 0.6;
  margin-top: 0.3125rem;
  text-align: left;
}
.psm-swap-container .psm-swap .psm-swap-box {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 3.3125rem;
}
.psm-swap-container .psm-swap .psm-swap-box .swap-input-box.swap-from {
  margin-bottom: 4.75rem;
}
.psm-swap-container .psm-swap .psm-swap-box .swap-input-box .swap-input-title {
  justify-content: space-between;
  align-items: baseline;
  display: flex;
  flex-direction: row;
  /* margin-bottom: 0.625rem; */
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input-title
  .swap-input-title-text {  
  font-style: normal;
  /* font-weight: 700; */
  font-size: 1rem;
  line-height: 2.5625rem;
  color: #fff;
}

.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input-title
  .title-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input-title
  .title-info
  .swap-balance-text {
  font-size: 0.875rem;
  font-weight: 500;
  color: hsla(0, 0%, 100%, 0.6);
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input-title
  .title-info
  .swap-balance-value {
  
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  margin-left: 0.3125rem;
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input-title
  .title-info
  .swap-input-title-max.disabled {
  cursor: not-allowed;
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input-title
  .title-info
  .swap-input-title-max {
  font-weight: 500;
  font-size: 0.875rem;
  color: #82ffe1;
  margin-left: 0.3125rem;
  cursor: pointer;
  opacity: 0.7;
}
.psm-swap-container .psm-swap .psm-swap-box .swap-input-box .swap-input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  
  font-style: normal;
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input
  .ant-input {
  height: 2.45rem;
  line-height: 3.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 9.625rem;
  background: #312e42;
  border: 0.0625rem solid transparent;
  padding-left: 1.25rem;
  font-weight: 700;
  font-size: 1.05rem;
  color: #fff;
  border-radius: 0.325rem;
}

.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input
  .ant-input:focus {
  border: 0.0625rem solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(90deg, #312e42, #312e42),
    linear-gradient(90deg, #58e1d9, #a879fb);
}

.ant-input {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input
  .ant-select {
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  /* width: 9rem; */
  position: absolute;
  /* top: 0.3125rem;
  right: 0.3125rem; */
  /* top: 4.8125px; */
  top: 0;
  right: -4.6875px;
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input
  .ant-select
  .ant-select-selector {
  background: transparent;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.6875rem;
  color: #fff;
  width: 8.1875rem;
  height: 3.125rem;
  border: none;
  padding: 0 0.625rem;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input
  .ant-select
  .ant-select-selector
  .ant-select-selection-item {
  margin-right: 0.8125rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.6875rem;
  color: #fff;
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input
  .ant-select
  .ant-select-selector
  .ant-select-selection-item
  > img {
  width: 1rem;
  height: 1rem;
  margin-right: 0.625rem;
}
.ant-select-single .ant-select-selector .ant-select-selection-item:after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder:after,
.ant-select-single .ant-select-selector:after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: "\a0";
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
  line-height: 30px;
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input
  .ant-select
  .ant-select-arrow {
  right: 0.9375rem;
  top: 50%;
}
.psm-swap-container .psm-swap .psm-swap-box .swap-error-tip {
  margin-left: 1.25rem;
  margin-top: 0.625rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #f19771;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.psm-swap-container .psm-swap .psm-swap-box .swap-arrow {
  position: absolute;
  height: 3rem;
  width: 3rem;
  background-size: cover;
  background-repeat: no-repeat;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
  background-image: url("../img/swap-hover.e888680e.png");
}
.psm-swap-container .psm-swap .psm-swap-box .swap-arrow:hover {
  background-image: url("../img/swap.d0c7aa16.png");
}
.psm-swap-container .psm-swap .psm-swap-box .swap-input-box .swap-input-title {
  justify-content: space-between;
  align-items: baseline;
  display: flex;
  flex-direction: row;
  /* margin-bottom: 0.625rem; */
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input-title
  .swap-input-title-text {
  
  font-style: normal;
  /* font-weight: 700; */
  font-size: 1rem;
  line-height: 2.5625rem;
  color: #fff;
}
.psm-swap-container
  .psm-swap
  .psm-swap-box
  .swap-input-box
  .swap-input-title
  .title-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.psm-swap-container .psm-swap .swap-approve {
  /* background: linear-gradient(90deg, #58e1d9 17.4%, #a879fb 89.41%); */
  background: linear-gradient(18deg, #4caf50 17.4%, #064409 101.41%);
  border: 0;
  font-style: normal;
  display: flex;
  align-items: center;
  border-radius: 0.325rem;
  font-weight: 700;
  font-size: 1.05rem;
  line-height: 1.6875rem;
  width: 100%;
  height: 3rem;
  justify-content: center;
  margin-top: 3.05rem;
  cursor: pointer;
  /* text-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%); */
  box-shadow: 0 0 1.25rem linear-gradient(90deg, #58e1d9 17.4%, #a879fb 89.41%);
}
.INR-balance-text{
  font-size: 0.875rem;
  font-weight: 500;
  color: hsla(0, 0%, 100%, 0.6);
}
.INR-balance-value {
  
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  margin-left: 0.3125rem;
}


@media only screen and (max-width: 992px) {
  .psm-swap-container {
    max-width: 100%;
    margin: 2.5rem auto 0;
    height: auto;
    padding-bottom: 0;
    flex-direction: reverse;
  }
}
@media only screen and (max-width: 992px) {
  .login-wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0.9375rem;
  }
}
@media only screen and (max-width: 992px) {
  .login-wrapper .login-back {
    margin-bottom: 0.625rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .link-wallet {
    top: -3.375rem;
    right: 0.9375rem;
    padding: 0 1.9375rem;
    border-radius: 0.625rem;
    text-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%);
    font-size: 0.875rem;
    line-height: 2.125rem;
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container,
  .psm-swap-container .psm-swap-cont {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-description {
    padding: 0.625rem;
    border-top-left-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
    border-radius: 1.25rem;
    width: 40.5625rem;
    margin: 2.25rem  1.1625rem;
    border-right: none;
  }
}
@media only screen and (max-width: 768px) {
  .psm-swap-container .psm-description {

    /* width: 20rem; */
    width: auto;
 
  }
}

@media only screen and (max-width: 350px) {
  .psm-swap-container .psm-description {
    width: 17.5625rem;
  }
}
@media only screen and (max-width: 460px) {
  .connect-wallet {
    /* padding: 0 17px !important; */
    background-image: linear-gradient(90deg, #58e1d9 17.4%, #a879fb 89.41%);
    border-radius: 0.625rem;
    text-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%);
    font-weight: 500;
    font-size: 12px;
    line-height: 33px;
    letter-spacing: 0.02em;
    color: #fff;
    cursor: pointer;
  }
  .disconnect-style{
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 992px) {
  /* .psm-swap-container .psm-description:before {
    width: 5.92063rem;
    height: 6.25rem;
    top: -1.25rem;
    right: 2.20438rem;
  } */
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-description .des-content-title {
    font-size: 1.5rem;
    line-height: 2.125rem;
    margin-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-description .des-content {
    min-height: auto;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-description .des-content > div > div:first-child {
    margin-top: 0;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-description .des-content .des-item-content {
    padding-right: 0;
    font-size: 0.75rem;
    line-height: 1.0625rem;
    margin-top: 0.625rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-description
    .des-content
    .des-item-title:nth-child(3) {
    margin-top: 2.625rem;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-description .des-content .des-item-title {
    font-size: 1rem;
    line-height: 1.375rem;
    margin-top: 2.5rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-description .des-content .des-item-title {
    font-size: 1rem;
    line-height: 1.375rem;
    margin-top: 2.5rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-description .des-content .address-enter-btn {
    line-height: 2.0625rem !important;
    padding: 0 0.8125rem;
    border-radius: 0.375rem;
    margin-right: 0.625rem;
    font-size: 0.75rem;
  }
}

/* ----------------- Psm Swap --------------------- */
@media only screen and (max-width: 992px) {
  .psm-swap-container {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap {
    width: calc(100% - 1.875rem);
    padding: 1.25rem 0.9375rem 0.9375rem;
    margin-bottom: 0.9375rem;
    margin-left: 1.9375rem;
    margin-right: 0.9375rem;
    border-radius: 0.625rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .psm-swap-title {
    font-size: 0.75rem;
    line-height: 1.0625rem;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .psm-swap-title .psm-swap-title-text {
    font-size: 1.5rem;
    line-height: 2.125rem;
    margin-left: 0;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .psm-swap-des {
    font-size: 0.75rem;
    line-height: 1.0625rem;
    opacity: 0.6;
    margin-top: 0.3125rem;
    margin-left: 0;
    margin-bottom: 0.75rem;
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .psm-swap-amount-detail {
    border-radius: 0.5rem;
    padding: 0.1875rem 1.25rem 0.25rem;
    font-size: 0.75rem;
    line-height: 1.0625rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    height: 1.5rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .psm-swap-box {
    margin-top: 1.5625rem;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .psm-swap-box .swap-input-box.swap-from {
    margin-bottom: 3.125rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input-title {
    margin-bottom: 0.3125rem;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input-title
    .swap-input-title-text {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input-title
    .title-info
    .swap-balance-text {
    font-size: 0.75rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input-title
    .title-info
    .swap-balance-value,
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input-title
    .title-info
    .swap-input-title-max {
    font-size: 0.75rem;
    margin-left: 0.3125rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input-title
    .title-info
    .swap-input-title-max.disabled {
    cursor: not-allowed;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .psm-swap-box .swap-input-box .swap-input {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    
    font-style: normal;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-input {
    height: 2.5rem;
    line-height: 2.5rem;
    padding-right: 6.25rem;
    padding-left: 0.625rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-select {
    border-radius: 0.25rem;
    width: 5.9375rem;
    top: 0.25rem;
    right: 0.25rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-select
    .ant-select-selector {
    font-size: 0.875rem;
    line-height: 1.6875rem;
    width: 5.9375rem;
    height: 2rem;
    padding: 0 0.5rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-select
    .ant-select-selector
    .ant-select-selection-search-input {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-select
    .ant-select-selector
    .ant-select-selection-item {
    margin-right: 0.8125rem;
    font-size: 0.875rem;
    line-height: 1.6875rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-select
    .ant-select-selector
    .ant-select-selection-item
    > img {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.375rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .psm-swap-box .swap-error-tip {
    margin-left: 0.625rem;
    margin-top: 0.3125rem;
    font-size: 0.625rem;
    line-height: 0.875rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .psm-swap-box .swap-arrow {
    height: 1.875rem;
    width: 1.875rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input-title {
    margin-bottom: 0.3125rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input-title
    .title-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input-title
    .title-info
    .swap-balance-text {
    font-size: 0.75rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input-title
    .title-info
    .swap-balance-value,
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input-title
    .title-info
    .swap-input-title-max {
    font-size: 0.75rem;
    margin-left: 0.3125rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-input {
    height: 2.5rem;
    line-height: 2.5rem;
    padding-right: 6.25rem;
    padding-left: 0.625rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-select {
    border-radius: 0.25rem;
    width: 5.9375rem;
    top: 0.25rem;
    right: 0.25rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-select
    .ant-select-selector {
    font-size: 0.875rem;
    line-height: 1.6875rem;
    width: 5.9375rem;
    height: 2rem;
    padding: 0 0.5rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-select
    .ant-select-selector
    .ant-select-selection-item {
    margin-right: 0.212rem;
    font-size: 0.675rem;
    line-height: 1.6875rem;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-select
    .ant-select-selector
    .ant-select-selection-item
    > img {
    width: 1rem;
    height: 1rem;
    margin-right: 0.375rem;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-select
    .ant-select-arrow {
    right: 0.5rem;
    top: 50%;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .psm-swap-box .swap-error-tip {
    margin-left: 0.625rem;
    margin-top: 0.3125rem;
    font-size: 0.625rem;
    line-height: 0.875rem;
  }
}

@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .swap-approve {
    border-radius: 0.375rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    height: 2.5rem;
    margin-top: 1.25rem;
    box-shadow: 0 0 1.25rem
      linear-gradient(90deg, #58e1d9 17.4%, #a879fb 89.41%);
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container
    .psm-swap
    .psm-swap-box
    .swap-input-box
    .swap-input
    .ant-select
    .ant-select-arrow {
    right: 0.5rem;
    top: 50%;
  }
}
@media only screen and (max-width: 992px) {
  .psm-swap-container .psm-swap .psm-swap-box .swap-error-tip {
    margin-left: 0.625rem;
    margin-top: 0.3125rem;
    font-size: 0.625rem;
    line-height: 0.875rem;
  }
}
.dropdown-area-box ul>li:hover{
  background-color: #676768;
  cursor: pointer;
}
.dropdown-area-box ul>li{
  padding: 5px;
}
@media only screen and (max-width: 992px) {
  .style2 {
    height: 2.5rem;
    width: 5.5rem;
  }
}
@media only screen and (max-width: 992px) {
  .dropdown-menu2 {
    min-width: 6rem;
  }
}

.psm-swap .dropdown-menu {
  min-width: 8rem;
  background-color: #312e42;
  
}
@media only screen and (max-width: 992px) {
  .psm-swap .dropdown-menu {
    min-width: 5.5rem;
    background-color: #312e42;
    
  }
}

@media only screen and (max-width:500px){
  .mobile-screen{
    position: fixed;
    bottom: 0;
    background: #2c2640;
    z-index: 10000000;
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid #66dddd;
}
}
/* @media only screen and (min-width:500px){
  .screen{
    width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
}
} */

@media only screen and (max-width: 769px){

.mobile-scroll-table {
    display: grid;
    white-space: nowrap;
    overflow-x: auto;
    /* width: auto; */
    width: auto;
}
.mobile-width{
  width:500px;
}
}