.ecology {
 
  text-align: center;
}
.ecology h4 {
  margin-top: 70px;
  margin-bottom: 3.75rem;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 3.125rem;
}
.ecology-component {

  border-radius: 1.25rem;
}
/* @media screen and (min-width: 992px) and (max-width:1281px) {

  .ecology-component .ecology-contain .ecology-list .item {
    width: 17rem !important;   
  }
  
  } */




.ecology-component .ecology-header {
  padding: 0 2.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ecology-component .ecology-header .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ecology-component .ecology-header .title span {
  font-style: normal;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 100%;
  letter-spacing: 0.02em;
}
.ecology-component .ecology-header .title .apy {
  padding: 0.4375rem 0.75rem;
  margin-left: 0.625rem;
  background: linear-gradient(
    80.69deg,
    #5417ff -10.31%,
    #9d1dca 38.43%,
    #ffa800 93.61%
  );
  font-family: "Avenir Next";
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.02em;
  border-radius: 0.3125rem;
  text-shadow: 0 0.0625rem 0.125rem rgb(99 1 1 / 56%);
}
.ecology-component .ecology-contain .ecology-list .item {
  min-height: 0.125rem;
  position: relative;
  background: rgba(32, 31, 43, 0.3);
  box-shadow: 0 0.25rem 1.25rem rgb(0 0 0 / 30%);
  -webkit-backdrop-filter: blur(1.875rem);
  backdrop-filter: blur(1.875rem);
  border-radius: 0.625rem;
  transition: transform 0.6s;
  transition-timing-function: ease;
 
}
@keyframes rotate {
  0% {
      background-position: 0% 50%
  }

  50% {
      background-position: 100% 50%
  }

  100% {
      background-position: 0% 50%
  }
}
.ecology-component .ecology-contain .ecology-list .item:before {
  display: none;
  box-sizing: border-box;
  content: "";
  position: absolute;
  z-index: 2;
  inset: 0;
  padding: 0.125rem;
  border-radius: 0.625rem;
  background: linear-gradient(45deg,#ff9141,#ac2eff,#03c2fe,#66ffed);
  background-size: 400% 400%;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: rotate 3s linear infinite;
  animation: rotate 3s linear infinite;
  overflow: hidden;
 }
.ecology-component .ecology-contain .ecology-list .item:hover {
  -webkit-transform: translateY(-0.625rem);
  transform: translateY(-0.625rem);
  border-top: none;
  cursor: pointer;
}
.ecology-component .ecology-contain .ecology-list .item:hover:before {
  display: block;
}
.eco-bg-img{
  background-image: url("/src/img//bg/top-ban.svg");
  background-position-x: 10%;
  background-position-y: 10%;
  background-repeat: no-repeat;
}
.ecology-component .ecology-contain {
  margin-top: 1.6875rem;
  padding: 0 0 0.125rem;
  position: relative;
  /* background-color: #101010; */
  background-image: url("/src/img//bg/top-ban.svg");
  
  background-repeat: no-repeat;
  background-size: 18.5rem 22.875rem, 55rem 12.5rem;
  background-position: 100% 100%, 0 0;

  overflow: hidden;
  border-radius: 1.25rem;
}

@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain {
    margin-left: 0px !important;
  }
}
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.ecology-component .ecology-contain .scrollbar-wrapper {
  padding: 0 1.5rem;

}
.ecology-component .ecology-contain .ecology-list {
  min-height: 100%;
  padding-top: 1.5rem;
  /* display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; */
  
}
.ecology-component .ecology-contain .ecology-list > a {
  display: block;
  margin-bottom: 2.5rem;
}
.ecology-component .ecology-contain .ecology-list .item .item-top .origin-box h5 .label_fixed, .ecology-component .ecology-contain .ecology-list .item .item-top .origin-box h5 .label_flexible {
  padding: 0.125rem 0.625rem;
  line-height: 1rem;
  border-radius: 0.875rem;
  font-family: "Avenir Next";
  font-weight: 600;
  font-size: .75rem;
  letter-spacing: .02em;
}

.ecology-component .ecology-contain .ecology-list .item .type-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1.9375rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  border-top-right-radius: 0.625rem;
  overflow: hidden;
}
.ecology-component .ecology-contain .ecology-list .item .type-box .token {
  padding: 0 0.75rem;
  height: 100%;
  background: hsla(0, 0%, 100%, 0.2);
  border-bottom-left-radius: 0.625rem;
  background: linear-gradient(
    80.69deg,
    rgba(84, 23, 255, 0.2) -10.31%,
    rgba(157, 29, 202, 0.2) 38.43%,
    rgba(255, 168, 0, 0.2) 93.61%
  );
}
.ecology-component .ecology-contain .ecology-list .item .type-box .token span {
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #fff;
 
}
.ecology-component .ecology-contain .ecology-list .item .type-box .token img {
  margin-right: 0.3125rem;
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
}
.ecology-component .ecology-contain .ecology-list .item .item-top {
  margin-bottom: 1.25rem;
}
.ecology-component .ecology-contain .ecology-list .item .type-box .token img {
  margin-right: 0.3125rem;
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
}
.ecology-component .ecology-contain .ecology-list .item .item-top .origin-box {
  text-align: left;
  padding-top: 35px;
  display: flex;
    justify-content: space-between;
}



@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .ecology-list .item .item-top .origin-box {
  
      justify-content:space-evenly;
  }
}
.ecology-component
  .ecology-contain
  .ecology-list
  .item
  .item-top
  .origin-box
  h5 {
  margin-bottom: 0.125rem;
  /* font-family: "Avenir Next"; */
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.625rem;
  color: #fff;
}
.ecology-component
  .ecology-contain
  .ecology-list
  .item
  .item-top
  .origin-box
  h5
  span {
  margin-right: 0.3125rem;
  vertical-align: middle;
}
@media only screen and (max-width:992px) {
  .ecology-component
  .ecology-contain
  .ecology-list
  .item
  .item-top
  .origin-box
  h5
  span {
  margin-right: 0.3125rem;
  vertical-align: middle;
  padding-left: 5px;
  font-size: 0.9rem;
}
}
.ecology-component
  .ecology-contain
  .ecology-list
  .item
  .item-top
  .origin-box
  h5
  .label-wrapper {
  display: inline-block;
}
.ecology-component
  .ecology-contain
  .ecology-list
  .item
  .item-top
  .origin-box
  h5
  .label_flexible {
  background: rgba(132, 255, 224, 0.1);
  color: rgba(132, 255, 224, 0.8);
}
.ecology-component .ecology-header .filter-wrapper .tab-btn-list button.active,
.ecology-component .ecology-header .filter-wrapper .tab-btn-list button:hover {
  font-weight: 600;
  border-radius: 0.25rem;
  border: none !important;
  background-image: linear-gradient(135deg,#8a2387,#e94057 50%,#f27121);
  color: #ffffff;
}
.ecology-component .ecology-header .filter-wrapper .tab-btn-list button {
  min-width: 4.25rem;
  margin-left: 0;
  padding: 0 0.3125rem;
  line-height: 1;
  height: 1.625rem;
  border-radius: 0.3125rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #101010;
  border: 1px solid #e94057;
  margin-left: 8px;
}
.ecology-component
  .ecology-contain
  .ecology-list
  .item
  .item-top
  .origin-box
  h5
  .label_fixed {
  background: rgba(255, 106, 106, 0.1);
  color: rgba(255, 106, 106, 0.8);
}
.ecology-component
  .ecology-contain
  .ecology-list
  .item
  .item-top
  .origin-box
  > div
  span {
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.02em;
  color: hsla(0, 0%, 100%, 0.6);
}

.ecology-component
  .ecology-contain
  .ecology-list
  .item
  .item-top
  .origin-box
  > div
  img {
  width: 0.875rem;
  height: 0.875rem;
  vertical-align: middle;
  margin-left: 0.25rem;
}
.ecology-component .ecology-contain .ecology-list .item .item-bot {
  padding-top: 1.25rem;
  border-top: 0.03125rem solid hsla(0, 0%, 85.1%, 0.15);
}
.ecology-component
  .ecology-contain
  .ecology-list
  .item
  .item-bot
  > div:first-child {
  margin-bottom: 0.5rem;
}
.ecology-component .ecology-contain .ecology-list .item .item-bot > div span {
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 137.36%;
  letter-spacing: 0.02em;
  color: hsla(0, 0%, 100%, 0.8);
}
.ecology-component .ecology-contain .ecology-list .item .item-bot > div span {
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 137.36%;
  letter-spacing: 0.02em;
  color: hsla(0, 0%, 100%, 0.8);
}

.flex-between {
  justify-content: space-between;
}
.ecology-component .ecology-contain .ecology-list .item .item-bot > div em {
  font-family: "Avenir Next Condensed";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.6875rem;
  letter-spacing: 0.02em;
  color: #fff;
}
.ecology-component .ecology-contain .ecology-list .item .item-bot > div b {
  font-family: "Avenir Next Condensed";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.02em;
  background: linear-gradient(90.23deg, #82ffe1 0.16%, #b0fcbf 99.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  vertical-align: bottom;
}

.ecology-contain::-webkit-scrollbar {
  width: 10px;
}


.ecology-contain::-webkit-scrollbar-thumb {
  background: rgb(34, 34, 34);
  border-radius: 10px;
}

/* Handle on hover */
.ecology-contain::-webkit-scrollbar-thumb:hover {
  background: rgb(70, 70, 70);
}

@media only screen and (max-width: 768px) {
  .ecology {
    padding: 0 1.25rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology h4 {
    margin-bottom: 1.125rem;
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component {
    width: 100%;
    border-radius: 0.625rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-header {
    padding: 0 1rem;
    flex-direction: column;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-header .title {
    position: relative;
    width: 100%;
    height: 1.25rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-header .title span {
    font-size: 0.875rem;
    line-height: 100%;
    /* font-family: "Avenir Next"; */
    letter-spacing: 0.02em;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-header .title .apy {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.375rem 0.5rem 0.25rem;
    margin-left: 0.625rem;
    font-size: 0.75rem;
    line-height: 100%;
    border-radius: 0.3125rem;
  }
}
@media only screen and (max-width: 420px) {
  .ecology-component .ecology-contain .ecology-list .item {
    width: 19rem;

    border-radius: 0.625rem;
  }
}
@media only screen and (max-width: 992px) {
  .ecology-component .ecology-contain .scrollbar-wrapper {
    padding:  0.6rem;
    /* height: 18.53125rem; */
  }
}

@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain {
    margin-top: 0.9375rem;
    padding: 0 0 0.125rem;
    background-size: 10.4375rem 12.875rem, 20.9375rem 8.25rem;
   
    overflow: hidden;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .scrollbar-wrapper {
    height: 14.625rem;
    padding: 0 1rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .ecology-list {
    min-height: auto;
    padding-top: 1.25rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-header {
    padding: 0 1rem;
    flex-direction: column;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .ecology-list > a {
    width: 100%;
    margin-bottom: 1.25rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .ecology-list .item {
    width: 100%;
    min-height: auto;
    border-radius: 0.625rem; 
  }
}
 


@media only screen and (max-width: 768px) {
  .ecology-component .ecology-header .title .apy {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.375rem 0.5rem 0.25rem;
    margin-left: 0.625rem;
    font-size: 0.75rem;
    line-height: 100%;
    border-radius: 0.3125rem;
  }
}
@media only screen and (max-width: 420px) {
  .ecology-component .ecology-contain .ecology-list .item .pool-fire {
    left: -0.5625rem;
    top: 0.5625rem;
    width: 3.1875rem;
    height: 1.25rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .ecology-list .item .type-box {
    height: 1.625rem;
  }
}
@media only screen and (max-width: 420px) {
  .ecology-component .ecology-contain .ecology-list .item .type-box .token {
    padding: 0 0.6875rem;
    height: 100%;
    border-bottom-left-radius: 0.625rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .ecology-list .item .type-box .token img {
    margin-right: 0.3125rem;
    width: 0.8125rem;
    height: 0.8125rem;
  }
}
@media only screen and (max-width: 420px) {
  .ecology-component .ecology-contain .ecology-list .item .type-box .token {
    padding: 0 0.6875rem;
    height: 100%;
    border-bottom-left-radius: 0.625rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component
    .ecology-contain
    .ecology-list
    .item
    .type-box
    .token
    span {
    /* font-family: "Avenir Next"; */
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #fff;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .ecology-list .item .item-top {
    margin-bottom: 0.625rem;
  }
}
@media only screen and (max-width: 420px) {
  .ecology-component .ecology-contain .ecology-list .item .item-top > img {
    height: 2.625rem;
    margin-right: 0.625rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component
    .ecology-contain
    .ecology-list
    .item
    .item-top
    .origin-box
    h5 {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    line-height: 137.36%;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component
    .ecology-contain
    .ecology-list
    .item
    .item-top
    .origin-box
    h5
    span {
    margin-right: 0.3125rem;
    vertical-align: middle;
  }
}
@media only screen and (max-width: 420px) {
  .ecology-component
    .ecology-contain
    .ecology-list
    .item
    .item-top
    .origin-box
    h5
    .label-wrapper {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component
    .ecology-contain
    .ecology-list
    .item
    .item-top
    .origin-box
    h5
    .label_fixed,
  .ecology-component
    .ecology-contain
    .ecology-list
    .item
    .item-top
    .origin-box
    h5
    .label_flexible {
    padding: 0.0625rem 0.625rem;
    line-height: 0.875rem;
    border-radius: 0.5625rem;
    font-size: 0.625rem;
  }
}

@media only screen and (max-width: 768px) {
  .ecology-component
    .ecology-contain
    .ecology-list
    .item
    .item-top
    .origin-box
    h5
    span {
    margin-right: 0.3125rem;
    vertical-align: middle;
  }
}
@media only screen and (max-width: 420px) {
  .ecology-component
    .ecology-contain
    .ecology-list
    .item
    .item-top
    .origin-box
    h5
    .label-wrapper {
    display: block;
    margin-top:7px;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component
    .ecology-contain
    .ecology-list
    .item
    .item-top
    .origin-box
    h5
    .label_fixed,
  .ecology-component
    .ecology-contain
    .ecology-list
    .item
    .item-top
    .origin-box
    h5
    .label_flexible {
    padding: 0.0625rem 0.625rem;
    line-height: 0.875rem;
    border-radius: 0.5625rem;
    font-size: 0.625rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .ecology-list .item .item-bot {
    padding-top: 0.625rem;
  }
}
@media only screen and (max-width: 420px) {
  .ecology-component
    .ecology-contain
    .ecology-list
    .item
    .item-bot
    > div:first-child {
    margin-bottom: 0.4375rem;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .ecology-list .item .item-bot > div span {
    font-size: 0.75rem;
    line-height: 142.8%;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .ecology-list .item .item-bot > div em {
    font-size: 1rem;
    line-height: 137.36%;
  }
}
@media only screen and (max-width: 420px) {
  .ecology-component .ecology-contain .ecology-list .item .item-bot > div b {
    font-size: 1.5rem;
    line-height: 108.6%;
    background: linear-gradient(
      88.18deg,
      #5bc08b,
      #54bbb3 32.48%,
      #a495ff 62.19%,
      #c2b8ff 93.59%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
@media only screen and (max-width: 768px) {
  .ecology-component .ecology-contain .ecology-list .item .item-bot > div span {
    font-size: 0.75rem;
    line-height: 142.8%;
  }
}
.ecology-component .ecology-contain .ecology-list .item .pool-fire {
  position: absolute;
  left: -0.65625rem;
  top: 0.65625rem;
  width: 3.75rem;
  height: 1.5rem;
  z-index: 10;
  background-image: url("../img/pool_fire.1446145e.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
