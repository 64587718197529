
.slider-2{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    --webkit-box-pack: center;
    --webkit-justify-content: center;
    --ms-flex-pack: center;
    justify-content: center;
    --webkit-box-align: center;
    --webkit-align-items: center;
    --ms-flex-align: center;
    align-items: center;
    --webkit-align-content: center;
    --ms-flex-line-pack: center;
    align-content: center;
    background-color: transparent;
}
.w-slider{
    position: relative;
    /* height: 300px; */
    text-align: center;
    /* background: #ddd; */
    clear: both;
    --webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0,0,0,0);
}
.mask-2 {
    --webkit-align-self: center;
    --ms-flex-item-align: center;
    --ms-grid-row-align: center;
    align-self: center;
}
.w-slider-mask {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
    left: 0;
    right: 0;
    height: 100%;
    white-space: nowrap;
}
.w-slide {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    white-space: normal;
    text-align: left;
}
.div-block-335 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.image-94 {
    /* width: 128px; */
    /* height: 32px; */
}
.paragraph-23 {
    max-width: 40ch;
    margin-top: 1em;
    margin-bottom: 1em;
    color: #f1f1ff;
    font-size: 1.675rem;
    line-height: 2.25rem;
    font-weight: 600;
    text-align: center;
    letter-spacing: -.025em;
}
.image-95 {
    width: 40px;
    height: 40px;
    margin-bottom: 0.5em;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.heading-77 {
    color: #f1f1ff;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
}
.text-block-84 {
    color: rgba(255,255,255,.6);
    font-size: .875rem;
    line-height: 1.25rem;
    text-align: center;
}

.div-block-402 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0;
    grid-column-gap: 0.1rem;
    grid-row-gap: 0.1rem;
}
.center {
    display: --webkit-box;
    display: --webkit-flex;
    display: --ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    --webkit-box-orient: vertical;
    --webkit-box-direction: normal;
    --webkit-flex-direction: column;
    --ms-flex-direction: column;
    flex-direction: column;
    --webkit-box-align: center;
    --webkit-align-items: center;
    --ms-flex-align: center;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 767px){
    .paragraph-23 {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}
@media screen and (max-width: 991px){
    .paragraph-23 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}
