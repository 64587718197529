/* .navbar {
    background-color: #222329;
} */
.tdr a {
  text-decoration: none;
}

.nav-set-bar {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.header-left {
  display: flex;
  align-items: center;
  /* margin-left: 3rem; */

}

.nav-hide-item {
  display: block;
}

@media only screen and (max-width: 768px) {
  .nav-hide-item {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .header-left {
    margin-left: 0.5rem;
    /* margin-top: -13px; */
  }

  .head-bottom-border {
    padding: 3px;

  }

  .navbar-collapse {
    /* flex-basis: 0; */
    padding-bottom: 10px;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: #020314 !important;

  }

  .borderNN {
    border: 1px solid rgba(255, 255, 255, .12) !important;
  }

  .nav-link11 {
    border-bottom: 1px solid rgba(255, 255, 255, .12);
  }
}

.borderNN {
  border: 1px solid rgba(255, 255, 255, 0.329) !important;
  margin-right: 15px;
}

.mob_navs {
  justify-content: center;
}

.header-logo{
  font-size: 2rem !important;
}


.navbar-toggler-icon {
  display: inline-block;
  width: 3.5em !important;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-light .navbar-toggler {
  margin-top: -13px;
}

.header-left>img {
  display: block;
  width: 3.2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.logo-right {
  position: relative;
  top: 7px;
}

.header-left>div {
  color: #fff;
}

.header-left-usdd {
  display: block;
  width: 2.825rem;
  margin-top: 0.1875rem;
  margin-bottom: 0.2rem;
}

.header-left>div span {
  line-height: 1.1875rem;
  /* font-family: "Avenir Next"; */
  font-weight: 500;
  font-size: 0.675rem;
  opacity: 0.8;
  letter-spacing: 0.02em;
}

.logo_subtext {
  font-size: 11.6px;
}


.navbar-nav {
  margin-right: 5rem;
}

.navbar-upper {
  height: 51px;
  background-color: #22232970 !important;
}

.navbar-brand {
  font-size: 0.5rem;
}

.header-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  background: rgba(210, 215, 255, 0.15);
  -webkit-backdrop-filter: blur(0.625rem);
  backdrop-filter: blur(0.625rem);
}

.data-info {
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, .12);
  font-size: .75rem;
  line-height: 1rem;
  text-align: center;
}

.data-info .value {
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 1.25rem;
  color: #fff;
  margin-right: 1.25rem;
  /* font-family: "Avenir Next Condensed"; */
  letter-spacing: 0.02em;
}

@media screen and (max-width:500px) {
  .data-info .value {
    font-size: 0.45rem;
  }

  .data-info .label {
    font-size: 0.475rem !important;
  }

}

.data-info .home_backing,
.data-info .totalNumber {
  display: inline-flex;
  align-items: center;
}

.data-info .label {
  font-weight: 600;
  font-size: 0.675rem;
  line-height: 1.25rem;
  color: hsla(0, 0%, 100%, 0.4);
  margin: 0 0.375rem 0 0;
}

/* .data-info .value {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #fff;
  margin-right: 1.25rem;
  font-family: "Avenir Next Condensed";
  letter-spacing: 0.02em;
} */

.data-info .view {
  font-weight: 600;
  font-size: 0.675rem;
  line-height: 1.25rem;
  color: #5bc08b;
  cursor: pointer;
  text-decoration: underline;
}

.tdr {
  display: flex;
  align-items: center;
  padding-right: 1.25rem;
}

.tdr-btn {
  border: 0.03125rem solid #fff;
  border-radius: 0.625rem;
  padding: 0.4375rem 1rem;
  color: #fff;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
  font-family: "Avenir Next";
}

.tdr-btn img {
  margin-left: 0.625rem;
}

@media only screen and (max-width: 768px) {
  .header-head {
    height: 6.25rem;
    flex-wrap: wrap;
    height: auto;
    padding-left: 0.3375rem;
  }
}

@media only screen and (max-width: 768px) {
  .header-head .data-info {
    display: flex;
    white-space: nowrap;
    padding: 0.875rem 0.25rem 0.875rem 0;
  }
}

@media only screen and (max-width: 768px) {
  .header-head .data-info .set-margin-m {
    margin-right: 0.25rem;
  }
}

@media only screen and (max-width: 768px) {
  .header-head .data-info .label {
    font-size: 0.525rem;
    line-height: 0.875rem;
    margin: 0 0.375rem 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .header-head .data-info .value {
    font-size: .525rem;
    line-height: .875rem;
    margin: 0 0.275rem 0 0;
  }
}

@media only screen and (max-width: 321px) {
  .header-head .data-info .label {
    font-size: 0.425rem;
  }
}

@media only screen and (max-width: 321px) {
  .header-head .data-info .value {
    font-size: 0.625rem;
  }
}

@media only screen and (max-width: 768px) {
  .header-head .data-info .mr0 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .header-head .data-info .view-mobile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .header-head .tdr {
    margin-top: 0;
    padding-bottom: 0.875rem;
  }
}

@media only screen and (max-width: 768px) {
  .header-head .tdr-btn {
    border: 0.03125rem solid #fff;
    border-radius: 1.125rem;
    padding: 0.125rem 0.625rem;
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 0.875rem;
  }
}

@media only screen and (max-width: 768px) {
  .header-head .tdr-btn img {
    margin-left: 0.25rem;
  }
}

.my-sticky {
  /* position: sticky;
  top: 0;
  background: #414350f0;
  height: 55px;
  z-index:10 */
  height: 2.5rem;
  /* position: fixed; */
  top: 0px;
  z-index: 900;
  background: rgba(210, 215, 255, 0.15) none repeat scroll 0% 0%;
  width: 100%;
  transform: translateZ(0px);
  backdrop-filter: blur(3.125rem) !important;
}

.dropdown-toggle {

  color: #fff !important;
}

.token-contact .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.15rem 0.15rem;
  font-size: 15px;
  font-weight: 500;
  color: #212529;
  border: 0;
}

@media only screen and (max-width: 768px) {
  .token-contact .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.15rem 0.15rem;
    font-size: 12px;
    font-weight: 500;
    color: #212529;
    border: 0;
  }
}

.audit-report .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.15rem 0.15rem;
  font-size: 12px;
  font-weight: 400;
  color: #212529;
  border: 0;
}

@media only screen and (max-width: 768px) {
  .audit-report .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.15rem 0.15rem;
    font-size: 8px;
    font-weight: 200;
    color: #212529;
    border: 0;
  }
}


.navbar-expand-lg .navbar-nav .dropdown-menu {
  background-color: #bfbebe;
}

.head-btn {
  padding: 1px;
  border-radius: 0.25rem;
  background-image: linear-gradient(135deg, #8a2387, #e94057 50%, #f27121);
}

.btn-head {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: #020314;
  color: #ededef;
  font-size: .875rem;
  line-height: .875rem;
}

.btn-head:hover {
  background-color: #fff !important;
  color: #000 !important;
  text-decoration: none !important;
}

a:hover {
  color: #fff !important;
}

.w-button {
  border: 0;
  text-decoration: none;
  cursor: pointer;
}

.navbar-nav .nav-link11 {
  padding-right: 0;
  padding-left: 0;
  margin-left: 10px;
  margin-right: 10px;
}


@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link11 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .padding-0 {
    padding: 0px !important;
  }
}

.nav-link11:hover {
  color: #ffffff !important;
}

.nav-link11.active {
  color: #ffffff !important;
  font-weight: 500;
}

.nav-link11 {
  display: block;
  padding: 0.5rem 1rem;
  color: #aaaaaa ;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  font-size: 1rem;
  line-height: 1.25rem;
}

.navbar-light .navbar-toggler {
  color: none !important;
  border: none !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}


.psm-nav-btn {
  background: linear-gradient(90deg, #4cafa9 17.4%, rgba(168, 121, 251, .81) 89.41%) !important;
}

.navbar-light .navbar-toggler {
  background: #d5386700;
}


.navbar-light .navbar-toggler-icon { 
  background-image: none !important;
}

.btn-secondary {
  background-color: #6c757d1a;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #303132;
}

.dropdown-item:hover {
  background-color: #303132;
}

.navbar-nav .dropdown-menu1 {
  position: static;
}

.dropdown-menu1.show {
  display: block;
}

.dropdown-menu1[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
  padding: 10px;
}

.dropdown-item11 {
  display: block;
  width: 100%;
  padding-left: 15px;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: white !important;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  border-radius: 6px;
}

.text-block-97 {
  color: rgba(255, 255, 255, .6);
  font-size: .75rem;
  line-height: 1rem;
}

.dropdown-item11:hover {
  /* background-color: tomato; */
  background-color: #191a2b;
  text-decoration: none;
  color: white !important;
}

.dropdown-menu1 {
  border: none !important;
  border-radius: 10px;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 17rem !important;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: white !important;
  text-align: left;
  list-style: none;
  background: #0d0e1f !important;
  /* background-image: linear-gradient(135deg, #8a0086, #920481 8%, #9b097c 18%, #a00c79 23%, #a91174 32%, #b1166f 41%, #bc1d68 53%, #c72361 65%, #d2295b 76%, #df3153 90%, #e9364e 100%, #f27121) !important; */
  /* background-image: linear-gradient(140.14deg,rgba(0,182,191,.15),rgba(27,22,89,.1) 86.61%),linear-gradient(321.82deg,#18134d,#1b1659) !important; */
  /* background-color: #1a2460; */
  background-clip: padding-box;
  border: 1pxsolidrgba(0, 0, 0, .15);
  border-radius: 0.25rem;

}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu1 {
    position: absolute;
  }

}

.mynav .connect-wallet {
  padding: 0 1.9375rem;
  background-image: linear-gradient(90deg, #58e1d9 17.4%, #a879fb 89.41%);
  border-radius: 0.625rem;
  text-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 2.125rem;
  letter-spacing: 0.02em;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
}

@media only screen and (max-width: 992px) {
  .mynav .connect-wallet {
    font-size: 9px;
    margin-top: -12px;
    line-height: 1.8rem;
    margin-right: 1px;
  }

  .head-connect {
    padding: 10px;
    width: 150px;
  }

  .margin-top-mob {
    margin: 25px 20px 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-nav {
    margin-top: -8px;
  }

}

.menu-container {
  display: flex;
  align-items: center;
}

.menu-button {
  display: block;
}

.menu-items {
  display: flex;
  flex-direction: column;
}