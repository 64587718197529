.ql-container{
    height: 300px;
}
.padding-class{
    padding: 60px;
}
.head-bg{
    background: #000;
    color: #6b7fb9; 
    border-radius: 10px;
}
.bg-back{
    background: #000;
    border-radius: 15px;
}
.action-border{
    border: 1px solid #6b7fb9;
    border-radius: 15px;
}
.action-class{
    color: #6b7fb9;
    background: #141041;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: 1px solid #6b7fb9;
}
.action-body{
    border-Bottom-left-radius: 15px;
    border-Bottom-right-radius: 15px;
    background: #141041;
}
.connect-wallet {
    padding: 0 1.9375rem;
    background-image: linear-gradient(90deg, #58e1d9 17.4%, #a879fb 89.41%);
    border-radius: 0.625rem;
    text-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 2.125rem;
    letter-spacing: 0.02em;
    color: #fff;
    cursor: pointer;
}
.quill {
    background: rgba(255, 255, 255, 0);
    border-radius: 15px;
    color:white
}
.ql-toolbar.ql-snow {
    border: 1px solid #6b7fb9;
    
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    color: white;
    background: aliceblue;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid #6b7fb9;
    border-top: 0px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #ffffff;
    background: #000;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fdfdfd;
    background-color: #000;
    background-clip: padding-box;
    border: 1px solid #6b7fb9;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control::placeholder{
    color: #6b7fb9;
}
.form-control:focus {
    color: #ffffff;
    background-color: #000;
    border-color: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
/* input {
    color-scheme: dark;
  } */

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
    }

    .form-control:disabled, .form-control[readonly] {
        background-color: #000;
        opacity: 1;
    }
    .bg-choices{
        border-radius: 15px;
        background:  #000;
    }
    .img-position{
        top: 50%;
        right: 10px;
    }

    @media only screen and (max-width:500px){
        .padding-class{
            padding: 10px;
        }  
        .head-bg{
            max-width: 90%;
        }
    }