.text-block-106 {
  display: inline-block;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid rgba(255, 255, 255, .12);
  border-radius: 0.5rem;
  color: #f1f1ff;
  font-size: .75rem;
  line-height: 1rem;
  font-weight: 500;
}

.text_heading {
  line-height: 1.5rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(255, 255, 255, .6);
  font-size: 0.8rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: -.025em;
}

.text-h1 {
  color: rgb(255, 255, 255);
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: 600;
}
@media screen and (max-width:500px) {
  .text-h1 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-weight: 600;
}
.mobile-text-hide{
  display: none ;
}
}

.text_para {
  width: 40%;
  line-height: 1.3rem;
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(255, 255, 255, .6);
  font-size: 0.9rem;
  /* line-height: 2rem; */
  /* font-weight: 600; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: -.025em;
}

.gradient--btn {
  padding: 1px;
  border-radius: 0.5rem;
  background-image: linear-gradient(135deg, #8a2387, #e94057 50%, #f27121);
}

.gradient---btn {
  display: block;
  margin-right: 0;
  margin-left: 0;
  padding: 0.75rem;
  border-style: none;
  border-radius: 0.5rem;
  background-color: #020314;
  -webkit-transition: 150ms;
  transition: 150ms;
  color: #ededef;
  font-size: 1rem;
  line-height: 1rem;
}

.gradient---btn:hover {
  background-color: #ffffffc2;
  color: #000!important;
  text-decoration: none;
}

.bg-top-3 {
  background-image: url("/src/img//bg/center-ban.svg");
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position-y: 91%;
  background-position-x: 76%;
}
@media (max-width: 576px){
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
}
}

@media screen and (max-width:992px) {
  .bg-top-3 {
    background-image: url("/src/img//bg/center-ban.svg");
    background-repeat: no-repeat;
    background-position-y: 91%;
    background-position-x: 59%;
  }
  .bg-top-4 {
    background-image: url("/src/img//bg/center-ban.svg");
    background-repeat: no-repeat;
    background-position-y: 60%;
    background-position-x: 59%;
}
}
.card-card {
  border-radius: 1rem;
  background-color: rgba(10, 11, 30, .5);
}

.card-border-ui {
  border: 1px solid rgba(255, 255, 255, .12);
  /* box-shadow: 1px 1px 52px 0 hsl(0deg 0% 100% / 20%); */
}

.panel-txt-container.justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.panel-txt-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.panel-heading {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: normal;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  color: #ededef;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: -.025em;
}

.text {
  margin-bottom: 0;
  color: rgba(255, 255, 255, .6);
}

.text {
  margin-bottom: 0;
  color: rgba(255, 255, 255, .6);
}

p {
  margin-top: 0rem;
  margin-bottom: 0rem;
  color: #f1f1ff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.panel-img-container {
  padding: 2rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.panel-img {
  width: 1040px;
  height: 339px;
  -o-object-fit: contain;
  object-fit: contain;
}

@media screen and (min-width:350px) {
  .text_para {
    width: 100% !important;
}
}
@media screen and (min-width:992px) {
  .text_para {
    width: 50% !important;
  }
}
.bg-card-color{
  background-color: #00000052 !important;
  box-shadow: 1px 1px 20px 0 hsl(0deg 0% 100% / 12%);
}

.progress{
  /* width:60%; */
  background-color: #8e80a7;
  border-radius: 20px;
  /* margin: auto; */
  padding-left: 0px;
  height: 12px;
}
.progress-bar {
  font-weight: 600 !important;
  /* background-image: linear-gradient(to right, rgb(76, 175, 169) 0%, rgb(168, 121, 251) 100%) !important; */
  background-image: linear-gradient(135deg,#8a2387,#e94057 50%,#f27121) !important;
}
.progress-broder{
  border: 1px solid #e8e3e324;
  background: #0000001a;
}
@media screen and (max-width: 480px) {
  .panel-heading {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}