.slide_contain {
    width: 100%;
    margin-top: 2em;
}

.slide_wrap {
    width: 100%;
}

.slide_list {  
    display: flex;
    width: 100%;  
    justify-content: flex-start; 
    align-items: stretch;
    overflow: hidden;
}

.slide_item {
    position: relative;
    z-index: 3;
    width: 34%;
    padding-right: 1.3em;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-transition: -webkit-transform 350ms;
    transition: transform 350ms;
    transition: transform 350ms, -webkit-transform 350ms;
}

.slide_card {
    height: 100%;
    padding: 0.1em;
    border: 1px solid hsla(0, 0%, 100%, .06);
    border-radius: 1em;
    background-color: rgba(20, 20, 20, .48);
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
}

.div-block-243 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 1em;
}

.quote {
    margin-top: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #ededef;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: left;
}
.div-block-220 {
    flex: 1;

}

.div-block-244 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.div-block-244 p{
    color: #ffaaef;
    margin-top: 1.5rem;
    /* font-style: italic; */
    
}

.slide_arrows {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 2.4em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.slide_arrow.is--left.is--disabled-arrow,
.slide_arrow.is--right {
    /* background-color: #ededef; */
}

.slide_arrow.is--left {
    /* background-color: #fff; */
}

/* a:active, a:hover {
    outline: 0;
} */
.w-inline-block {
    max-width: 100%;
    display: inline-block;
}


.collection-list-wrapper-4 {
    width: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
}

.collection-list-6 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    border-radius: 1.5em;
}

.job-posting {   
    display: flex;
    margin: 0.5em;
    padding: 1em;  
    flex-direction: row;   
    align-items: center;
    border: 1px solid hsla(0, 0%, 100%, .16);
    border-radius: 1em;
    background-color: rgba(20, 20, 20, .32);   
    transition: .2s;
}

.w-inline-block {
    max-width: 100%;
    /* display: inline-block; */
}

.reward-name-copy {
    /* margin-top: 0; */
    /* margin-bottom: 0; */
    /* margin-left: 0; */
    font-size: 1.2rem;
    /* line-height: 1.25rem; */
    /* font-weight: 600; */
}

.job-posting-description {
    color: #a09fa6;
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 400;
}

.text-block-44 {
    margin-left: 1em;
    color: #fff;
    font-size: 1.5em;
    line-height: 1.5;
    font-weight: 600;
}

.section-n.us.cta {
    background-color: #020314;
}

.section-n.us {
    padding-bottom: 4em;
    background-color: transparent;
}

/* .section-n {
    position: relative;
    overflow: hidden;
    padding-top: 6em;
    
} */
/*  */
@media screen and (max-width: 479px) {
    .section-paragraph {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .slide_item {
        width: 104% !important;
        padding-right: 4%;
    }

    .collection-list-6 {
        display: block !important;
        background-image: url("/src/img/bg/bg-mobile-1.svg"), url("/src/img/bg/bg_mobile.svg");
        background-position: 50% 50%, 50% 50%;
        background-size: contain, contain;
        background-repeat: repeat-y, repeat-y;
    }
}

@media screen and (max-width: 767px) {
    .slide_item {
        width: 80%;
    }

    .collection-list-6 {
        margin-right: 0;
        margin-left: 0;
    }
}

@media screen and (max-width: 991px) {
    .slide_contain {
        margin-top: 32px;
    }

    .collection-list-6 {
        display: -ms-grid;
        display: grid;
        margin-bottom: 20px;
        padding: 0;
        grid-auto-columns: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: auto;
        grid-template-rows: auto;
    }

    .div-block-220 {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .text-block-44 {
        font-size: 16px;
        line-height: 20px;
    }
}

@media screen and (min-width: 1440px) {
    .collection-list-wrapper-4 {
        margin-right: 1em;
    }

    .job-posting-description {
        color: hsla(0, 0%, 100%, .75);
    }
}