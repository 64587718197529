.fs-14 {
  font-size: 14px!important;
}

.bg-img {
  /* background-image: url("/img/bg.png");
    background-size: cover;
    background-repeat: no-repeat; */

}

/* @media only screen and (max-width: 768px) {
    .bg-img{
        background-position: center;
        background-repeat: no-repeat; 
    }
  } */
  .Typewriter{
    min-height: 37px;
  }
  .bg-top-img {
    position: absolute;
    height: 37%;
    /* filter: saturate(200%); */
    left: 50%;
    transform: translateX(-50%);
    top: 75px;
    object-fit: cover;
}
.ban-size {
  width: 51%;
}

@media screen and (max-width:500px) {
  .ban-size {
    width: 100% !important;
  }

  .bg-top-img {
    position: absolute;
    -webkit-filter: saturate(200%);
    filter: saturate(200%);
    left: 50%;
    transform: translateX(-50%);
    /* left: 0; */
    /* top: .5%; */
    /* right: 0; */
    /* bottom: 0; */
    /* z-index: 1; */
    /* width: 100%; */
    /* height: 90%; */
    max-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 50%;
  }
}

.Typewriter__cursor {
  display: none !important;
}

.text-ban {
  font-style: normal;
  font-size: 0.975rem;
  line-height: 1.65rem;
  letter-spacing: .02em;
}

.head-btn-drop {
  background-image: linear-gradient(135deg, #8a2387, #e94057 50%, #f27121);
}

.head-btn-ban {
  padding: 1px;
  border-radius: 0.25rem;
  background-image: linear-gradient(135deg, #8a2387, #e94057 50%, #f27121);
}

.Typewriter__wrapper,
.text-linear {
  /* background-image: linear-gradient(135deg, #8a0086, #920481 8%, #9b097c 18%, #a00c79 23%, #a91174 32%, #b1166f 41%, #bc1d68 53%, #c72361 65%, #d2295b 76%, #df3153 90%, #e9364e 100%, #f27121); */
  background-image: linear-gradient(to right, #4CB8C4 0%, #3CD3AD  51%, #4CB8C4  100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-head-ban {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: #02031400 !important;
  color: #ededef;
  font-size: .975rem;
  font-weight: 600;
  /* line-height: .875rem; */
}

.btn-head-ban:hover {
  /* background-color: #fff !important; */
  /* color: #000 !important; */
  text-decoration: none !important;
}

.w-button-ban {
  display: inline-block;
  /* padding: 9px 15px; */
  /* background-color: #3898ec; */
  /* color: #fff; */
  border: 0;
  /* line-height: inherit; */
  text-decoration: none;
  cursor: pointer;
  /* border-radius: 0; */
}

.bg-top-head {
  background-image: url("/src/img//bg/top-ban.svg");
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position-y: 1%;
  -webkit-filter: saturate(120%);
  filter: saturate(120%);
}

@media screen and (min-width:992px) {
  .bg-top-head {
    background-image: url("/src/img//bg/top-ban.svg");
    /* background-size: cover; */
    background-repeat: no-repeat;
    background-position-y: 1%;
    background-position-x: 46%;
  }
}

@media screen and (max-width:992px) {
  .bg-top-head {
    background-image: url("/src/img/bg/top-ban.svg");
    /* background-size: cover; */
    background-repeat: no-repeat;
    background-position-y: 1%;
    background-position-x: 46%;
  }
}

.bg-top-2 {
  background-image: url("/src/img/bg/center-ban.svg");
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position-y: -10%;
  -webkit-filter: saturate(200%);
  filter: saturate(120%);
}

.bg-top-4 {
  background-image: url("/src/img/bg/center-ban.svg");
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position-y: 45%;
  background-position-x: 50%;
  /* -webkit-filter: saturate(200%);
  filter: saturate(200%); */
}

.home-main {
  flex: 1 1;
  color: #fff;

}

@media only screen and (max-width: 768px) {
  .home-main {
    margin-left: 0px;
  }
}

.home-main .info-text-box {
  margin: 8.375rem auto 0;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .home-main .info-text-box {
    display: grid;
  }
}

.home-main .banner-img {
  height: 265px;
}


@media only screen and (max-width: 768px) {
  .home-main .banner-text {
    order: 2;
  }
}

@media only screen and (max-width: 768px) {
  .home-main .banner-image {
    order: 1;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .home-main .banner-img {
    height: 170px;
  }


}

@media only screen and (max-width: 992px) {
  .home-main .banner-img {
    height: 158px;
  }

  .ban-size {
    width: 100% !important;
  }
}
/* 
.home-main p {
  
  font-size: 0.85rem !important;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .1em;
} */

.home-main p a {
  display: inline-block;
  color: #448f6a;
  font-weight: 600;
}

.home-main .btn-list {
  margin-top: 1.5rem;
}

.home-main .tokenAddresses {
  border-radius: 0.25rem;
  background-image: linear-gradient(135deg, #8a2387, #e94057 50%, #f27121);
  height: 3.125rem;
  line-height: 3.125rem;
  /* margin-right: 1.875rem; */
  padding: 0 1.25rem;
  font-weight: 500;
  font-size: 1.125rem;
  color: white;
}

.nav-link1 {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(255 255 255) !important;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.home-main strong {
  display: block;
  margin: 1.8125rem 0 1.25rem;
  font-size: 4.5rem;
  line-height: 108.6%;
  /* font-weight: 700; */
  /* font-family: "Avenir Next"; */
  letter-spacing: .02em;
  font-weight: bold;
}

.dropdown-area-box ul>li:hover {
  background-color: #1e212526 !important;
  color: white !important;
}
.dropdown-area-box ul>li>a:hover {
  /* background-color: #1e212526 !important; */
  color: white !important;
}
.dropdown-area-box li {
  list-style: none;
}

.button-token {
  height: 45px;
}

.home-main .tokenAddresses {

  line-height: 3.125rem;
  /* margin-right: 1.875rem; */
  padding: 0 1.25rem;
  font-weight: 500;
  font-size: 0.975rem;
}

.button-style1 {
  background: hsla(0, 0%, 100%, .1);
  border: 0.0625rem solid hsla(0, 0%, 100%, .3);
}

.navbar-toggler {

  font-size: 0.75rem;

}

.button-wrapper {
  display: inline-block;
  border-radius: 0.325rem;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;
  -webkit-backdrop-filter: blur(.875rem);
  backdrop-filter: blur(0.875rem);
}

.button-style1:hover {
  background: hsla(0, 0%, 100%, .3);
  color: #fff;
}

@media only screen and (min-width: 769px) {
  .width-screen {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .home-main .info-text-box {
    width: auto;
    margin: 10.25rem auto 0 2.375rem;
  }
}

@media only screen and (max-width: 992px) {
  .home-main strong {
    margin: 1.875rem 0;
    font-size: 2.8rem;
    line-height: 108.6%;
  }

  /* .home-main p {
        margin-top: 0.625rem;
        font-size: .725rem;
        line-height: 1.25rem;
        letter-spacing: .1em;
    } */
}

@media only screen and (max-width: 600px) {
  .home-main strong {
    margin: 1.875rem 0;
    font-size: 2rem;
    line-height: 108.6%;
  }
}

/* @media only screen and (max-width: 600px) {
    .home-main p {
        max-width: 16.75rem;
        margin-top: 0.625rem;
        font-size: .5625rem;
        line-height: 1.25rem;
        letter-spacing: .1em;
        text-align: center; 
    }
  } */
@media only screen and (max-width: 600px) {
  .home-main .btn-list {
    margin-top: 1.25rem;
  }
}

/* @media only screen and (max-width: 600px) {
  .home-main .tokenAddresses {
    height: 1.875rem;
    line-height: 1.875rem;   
    padding: 0 0.625rem;
    font-size: .625rem!important;
  }
} */

@media only screen and (max-width: 600px) {
  .home-main .info-text-box {
    width: auto;
    margin: 10.25rem auto 0 2.375rem;
  }
}

@media only screen and (max-width: 600px) {
  .home-main .info-text-box {
    width: auto;
    margin: 5.25rem auto 0 2.375rem;
  }
}

@media only screen and (max-width:355px) {
  .home-main .info-text-box {
    width: auto;
    margin: 5.25rem auto 0 0.375rem;
  }
}

.token-contact .dropdown-menu .btn-logo {
  height: 20px;
}

@media only screen and (max-width: 600px) {
  .token-contact .dropdown-menu .btn-logo {
    height: 15px;
  }
}

.token-contact .dropdown-menu {
  min-width: 10.8rem !important;
  color: #212529;
  /* text-align: center; */
  list-style: none;
  background: black;
  border: 0.0625rem solid hsla(0, 0%, 100%, 0.3);
  border-radius: 0.325rem;
}

@media only screen and (max-width: 600px) {
  .token-contact .dropdown-menu {
    min-width: 8rem !important;
  }
}

.audit-report .dropdown-menu {
  min-width: 9.1rem !important;
  color: #ffffff;
  /* text-align: center; */
  list-style: none;
  /* background-image: linear-gradient(135deg, #8a2387, #e94057 50%, #f27121); */
  border: 0.0625rem solid rgba(255, 255, 255, .12);
  border-radius: 0.325rem;
  background: #0d0e1f;
}

@media only screen and (max-width: 600px) {
  .audit-report .dropdown-menu {
    min-width: 8rem !important;
  }
}

.dropdown-item1 {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff !important;
  /* padding-left: 5px; */

}


.dropdown-item1:focus,
.dropdown-item1:hover {
  color: #e6e6e6;
  background-color: #1e2125 !important;
}

.dropdown-area-box ul>li:hover {
  background-color: #1e2125 !important;

}

/* .home-main .btn-list a {
  color: #fff;
} */

.home-main .tokenAddresses {
  height: 2.6rem;
  line-height: 2.6rem;
  /* margin-right: 1.875rem; */
  padding: 0 1.25rem;
  font-weight: 600;
  font-size: 0.6;
}

.home-main .psm-link-btn {
  min-width: 9.125rem;
  background: linear-gradient(90deg, #4cafa9 17.4%, rgba(168, 121, 251, .81) 89.41%);
}
/* 
@media only screen and (max-width: 600px) {
  .home-main .tokenAddresses {
    height: 1.875rem;
    line-height: 1.875rem;
    padding: 0 0.625rem;
    font-size: .625rem;
  }
} */

@media only screen and (max-width: 992px) {
  .home-main .psm-link-btn {
    min-width: 2.25rem;
  }
}