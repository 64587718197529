

.wagmi_btn{
    /* background:linear-gradient(135deg, #8a2387, #e94057 50%, #f27121); */
color:#fff;
border-radius:5px;
padding: 5px 10px;
border:1px solid rgba(255, 255, 255, 0.329) !important;
background-color: transparent;
font-size: 12px;
}