/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,

  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;

  }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;

  }

  .swal2-footer a{
    color: #0d6efd !important;
  }
  .swal2-footer a :hover{
    color: #012458 !important;
  }

  .zindex{
    z-index: 9999999999;
  }

  .visible-scrollbar,
.invisible-scrollbar,
.mostly-customized-scrollbar {
  display: block;
  width: 10em;
  overflow: auto;
  height: 2em;
}

/* body::-webkit-scrollbar {
  display: none;
} */

/* Demonstrate a "mostly customized" scrollbar
 * (won't be visible otherwise if width/height is specified) */
 body::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #000; /* or add it to the track */
}

/* Add a thumb */
body::-webkit-scrollbar-thumb {
  background: rgb(70, 70, 70);
}