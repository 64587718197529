@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css);
/* @import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css); */
.font-text{
    font-family: 'Bebas Neue', cursive;
    font-family: 'Space Grotesk', sans-serif;
}
.btn-secondary1 {
    color: #6e7aae !important;
    background-color: #22222200 !important;
    border-color: #6e7aae !important;
}
.dropdown-menu {
    border: 1px solid #6e7aae;
    border-radius: 10px;
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-image: linear-gradient(140.14deg,rgba(0,182,191,.15),rgba(27,22,89,.1) 86.61%),linear-gradient(321.82deg,#18134d,#1b1659);
    /* background-color: #1a2460; */
    background-clip: padding-box;
    border: 1pxsolidrgba(0,0,0,.15);
    border-radius: 0.25rem;
}
.dropdown-item {
    color: #6b7aab !important;
    padding-left: 15px;
}
.dropdown-item:focus, .dropdown-item:hover {
    background-color: #15194f!important;
}
.input-transparent{
    font-size: 16px;
    line-height: 1.5;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23FFFFFF' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 20px 20px;
    width: 250px;
    padding: .5em 1em .5em 2.5em;
    margin-bottom: 0px;
    border-radius: 10px;
    border: 1px solid #6e7aae;
    } 
    input:focus { color: #6e7aae }
    
    .input-transparent::placeholder{
      color: #6e7aae;
    }
    
    .input-transparent:focus {
      outline: none;
      /* background: rgba(255, 255, 255, 100); */
      text-shadow: none;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%238C92A0' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
      background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 20px 20px;
    }
  .nav-item:nth-child(3){
    cursor: pointer;
  }  
.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 1.25rem;
}
.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: rgba(196,214,255,.5) !important;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff !important;
    background: linear-gradient(245.22deg, rgb(218, 46, 239), rgb(43, 106, 255), rgb(57, 208, 216)) 0% center / 400% 100%;
}
.border-rounded{
    border-radius: 25px;
}

.head-border{
    /* min-height: 300px; */
    border-radius: 21.2px;
    padding: 1.2px;
    border-radius: 20px;
    background-image: linear-gradient(var(--gradient-rotate, 246deg), #da2eef 7.97%, #2b6aff 49.17%, #39d0d8 92.1%);
}
.card-bg{
    border-radius: 20px;
    background-image: linear-gradient(140.14deg,rgba(0,182,191,.15),rgba(27,22,89,.1) 86.61%),linear-gradient(321.82deg,#18134d,#1b1659);
}
.text-head{
    border-radius: 10px;
    --tw-text-opacity: 1;
    color: rgb(171 196 255/var(--tw-text-opacity));
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.font-medium {
    font-weight: 500;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-color{
    color: rgba(196,214,255,.5) !important;
}
.bg-head{
    background-color: rgba(20, 16, 65, 0.397);
}


.text-primary{
    color : #5a8dee !important;
}
/* .card {
     box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
    background-color: #fff;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #475f7b;
    overflow: hidden;
} */
.rc-accordion-toggle {
    /* display: flex; */
    align-items: flex-start;
    cursor: pointer;
    justify-content: space-between;
    background-color: #141041;
    transition: 0.3s;
}
.rc-accordion-toggle.active{
    background-color: #141041;
}
.rc-accordion-toggle.active .rc-accordion-icon{
    transform: rotate(180deg);
    color: #fff;
}
.rc-accordion-card{
    /* border: 1px solid #ddd; */
    border-radius: 15px;
    margin-bottom: 10px;
    overflow: hidden;
}
.rc-accordion-card:last-child{
    margin-bottom: 0;
}
.rc-accordion-title{
    font-weight: 500;
    font-size: 14px;
    position: relative;
    margin-bottom: 0;
    color: #475F7B;
    transition: 0.3s;
}
.rc-accordion-toggle.active .rc-accordion-title{
    color: #fff;
}
.rc-accordion-icon{
    position: relative;
    top: 2px;
    color: #475F7B;
    transition: 0.35s;
    font-size: 12px;
}
.rc-accordion-body{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 15px;
}
.rc-accordion-body p{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #727E8C;
}
.rc-collapse{
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}
.rc-collapse.show{
    height: auto;
    background: #2b3a74;
}

.card-border{
    border: 1px solid rgba(171,196,255,.5);
    border-radius: 15px;
}
/* .btn-border{
    border: 0 solid #e5e7eb;
} */
.acc-btn{
    color: #3bd0d8;
    border-color: #58f3cd;
    background-image: linear-gradient(var(--gradient-rotate, 90deg),rgba(59,208,216,.2), rgba(59,208,216,0));

    /* background-color: rgba(59,208,216,.2);
    background-color: rgba(59,208,216,0); */

}
.acc-btn::before{
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: .7;
    background: transparent;
    border-radius: inherit;
    box-shadow: inset 0 0 0 var(--border-line-width,1.5px) var(--border-color);
    -webkit-mask-image: radial-gradient(at -31% -58%,rgba(0,0,0,.5) 34%,transparent 60%),linear-gradient(270deg,rgba(0,0,0,.2) 0,transparent 13%),linear-gradient(rgba(0,0,0,.05),rgba(0,0,0,.05));
    mask-image: radial-gradient(at -31% -58%,rgba(0,0,0,.5) 34%,transparent 60%),linear-gradient(270deg,rgba(0,0,0,.2) 0,transparent 13%),linear-gradient(rgba(0,0,0,.05),rgba(0,0,0,.05));
}

@media only screen and (max-width:500px){
    .acc-btn{
        font-size: 11px;
    }
    .nav-pills .nav-link {
        font-size: 12px;
    }
}
@media only screen and (max-width:769px){
    .mobile-scroll{
        display: grid;
        white-space: nowrap;
        overflow-y: scroll;
        width: auto;
    }
}