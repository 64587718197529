body {
  background-color: rgb(5, 1, 15);
  color: #fff;
  font-optical-sizing: auto;
  /* overflow-y: scroll;
  overflow-x: hidden; */
}
.text-purple {
  color: #DE32BD;
 
}
.cursor_pointer {
  cursor: pointer;
}

.copied_msg {
  background-color: #2ea024;
  border: 1px solid #0b0e44;
  color: #fff;;
  font-weight: normal;
  font-size: 13px;
  padding: 5px;
  border-radius: 5px;
 
}

.content_style h2 {
  font-size: 1.8rem;
  color: #fff;
  font-weight: bold;
}

.content_style h2:not(:first-of-type) {
  margin-top: 3.5rem
}

.content_style strong {
  color: #DE32BD;
  font-weight: 700;
}

.content_style h4 {
  position: relative;
  font-size: 1.5rem;
  padding-left: 40px;
  font-weight: bold;
}

.content_style h4:not(:first-of-type) {
  margin-top: 3rem
}

.content_style h4::before {
  content: '';
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url('/public/img/question.svg');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 8px;
}

.content_style.faq p {
  padding-left: 2.5rem
}


.content_style h3 {
  font-size: 1.3rem;
  margin-top: 1rem;
  font-weight: normal;
}

.img_30 {
  width: 30px;
  height: 30px;
}

.min-height-cards {
  min-height: 280px
}

.heading {
  font-size: 2.8rem;
  line-height: 3.5rem;
  font-weight: bold;
}

.mob_nav {
  display: flex;
  align-items: center;
  gap: 10px;
}

.above-footer {
  background-color: transparent;
  background-image: url(https://cdn.prod.website-files.com/609486d2fafa37698a57db5b/646dcb8fb0ba0c6aee77a44c_c-gradient-layer-blur.svg);
  background-position: 32% 0;
  background-size: 1066px;
  padding-top: 2em;
  padding-bottom: 8em;
  position: relative;
}

.flex_footer {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.flex_footer > div {
  display: flex;
  align-items: center; 
  justify-content: center;
}

.flex_footer > div:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, .12);
  padding-right: 1rem; 
}

.flex_footer > div:last-child {
  padding-right: 0; 
}

.custom_card_outline {
  border: 1px solid rgba(255, 255, 255, .12);
  border-radius: 1rem;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 0.8rem;
  display: flex;
  min-height: 210px;
}


.custom_card {
  background-color: #0f101f;
  border: 1px solid rgba(255, 255, 255, .06);
  border-radius: 1rem;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
}

.preview_card {
  background-color: #0f101f;
  border: 1px solid rgba(255, 255, 255, .06);
  border-radius: 1rem;
}

.preview_card_body {
  padding: 1rem;
}

.preview-card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  vertical-align: middle;
}

.preview_card:hover,
.custom_card:hover {
  border-color: rgba(255, 255, 255, .12);
  box-shadow: 0 0 16px rgba(255, 255, 255, .15);
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.text-gray {
  color: #9b9b9b;
}

.span-gradient {
  background-image: linear-gradient(137deg, #d831d3, #f8344f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


ul.list {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 40px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  list-style-type: disc;
}

ul.list li {
  color: #fff;
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.spacer {
  padding-top: 3em;
  padding-bottom: 3em;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.section-container {
  max-width: 800px;
  margin: 25px auto;
}

.call-to-action-section {
  z-index: -2;
  display: flex;
  padding: 0.15rem;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background-image: linear-gradient(to right, #fc00ff 0%, #00dbde 51%, #fc00ff 100%);
}

.call-to-action-inner {
  display: flex;
  width: 100%;
  padding: 4rem 2rem;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background-color: #0f142c;
}

.benefits-card {
  display: flex;
  margin: 0.25em;
  padding: 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-investors-logos {
  display: flex;
  margin-top: 2em;
  margin-right: 0;
  margin-left: 0;
  justify-content: center;
  flex-wrap: wrap;
  /* grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto; */
}


.okc-bridge-col {
  border: 1px solid #232326;
  background-color: #020314;
  border-radius: 16px;
  width: 100%;
  padding: 15px 20px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rotate-icon {  
  transform: rotate(0deg);
}

@media (max-width: 992px) {
  .rotate-icon {
    transform: rotate(90deg);
  }
}

.image-39 {
  width: 120px;
  height: 32px;
}


.privacy_h1 {
  display: block;
  margin: 1.8125rem 0 1.25rem;
  font-size: 3.6rem;
  line-height: 108.6%;
  font-weight: 700;
  /* font-family: "Avenir Next"; */
  letter-spacing: .02em;
  font-style: normal;
}

.privacy_h2 {
  display: block;
  margin: 1.8125rem 0 1.25rem;
  font-size: 2rem;
  line-height: 108.6%;
  font-weight: 700;
  /* font-family: "Avenir Next"; */
  letter-spacing: .02em;
  font-style: normal;
}

.fs-13 {
  font-size: 13px !important;
}

.btn-header {
  font-size: 14px;
  /* font-weight: 600; */
  color: #fff;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 5px;
  padding: 10px 20px;
  moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}


.btn-hover {
  font-size: 16px;
  /* font-weight: 600; */
  color: #fff;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 5px;
  padding: 10px 40px;
  moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-1 {
  background-image: linear-gradient(to right,
      #25aae1,
      #40e495,
      #30dd8a,
      #2bb673);
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}

.btn-hover.color-2 {
  background-image: linear-gradient(to right,
      #f5ce62,
      #e43603,
      #fa7199,
      #e85a19);
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
}

.btn-hover.color-3 {
  background-image: linear-gradient(to right,
      #667eea,
      #764ba2,
      #6b8dd6,
      #8e37d7);
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}

.btn-hover.color-4 {
  background-image: linear-gradient(to right, #76b852 0%, #8DC26F  51%, #76b852  100%)
}

.btn-hover.color-5 {
  background-image: linear-gradient(to right,
      #0ba360,
      #3cba92,
      #30dd8a,
      #2bb673);
  /* box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75); */
}

.btn-hover.color-6 {
  background-image: linear-gradient(to right,
      #009245,
      #fcee21,
      #00a8c5,
      #d9e021);
  box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75);
}

.btn-hover.color-7 {
  background-image: linear-gradient(to right,
      #6253e1,
      #852d91,
      #a3a1ff,
      #f24645);
  box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
}

.btn-hover.color-8 {
  background-image: linear-gradient(to right,
      #29323c,
      #485563,
      #2b5876,
      #4e4376);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}

.btn-hover.color-9 {
  background-image: linear-gradient(to right, #4568DC 0%, #B06AB3  51%, #4568DC  100%); 
}

.btn-hover.color-10 {
  background-image: linear-gradient(to right,
      #ed6ea0,
      #ec8c69,
      #f7186a,
      #fbb03b);
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}

.btn-hover.color-11 {
  background-image: linear-gradient(to right,
      #eb3941,
      #f15e64,
      #e14e53,
      #e2373f);
  box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
}


.btn-grad {
  background-image: linear-gradient(to right, #ECE9E6 0%, #FFFFFF 51%, #ECE9E6 100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #000;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #000;
  text-decoration: none;
}


.btn-grad-outline {
  background: hsla(0, 0%, 100%, 0.1);
  border: 0.0625rem solid hsla(0, 0%, 100%, 0.3) !important;
  background-size: 200% auto;
  border-radius: 4px;
  color: #fff;
  padding: 7px 12px;
  text-align: center;
  transition: .5s;
  white-space: nowrap;
}

.btn-grad-outline:hover {
  box-shadow: 0 0 6px hsla(0, 0%, 100%, 0.3);
  color: #65e6d4;
  text-decoration: none;
  background: #363636
    /* change the direction of the change here */

}

.button-outline {
  display: inline-block;
  padding: 2px;
  border-radius: 4px;
  background-image: linear-gradient(318deg, #00dbdb, #8000ff);
}




/* waves */
.ocean {
  height: 40%;
  /* change the height of the waves here */
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.wave {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 20s linear reverse infinite;
  opacity: 0.5;
}

.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 35s -1s linear infinite;
  opacity: 0.5;
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(-50%);
  }
}


.circle_bg {
  width: 20px;
  height: 20px;
  background-color: #29323c;
  border-radius: 50%;
  margin: 0 5px;
  text-align: center;
}

.circle_bg .ana_img {
  height: 10px;
  position: relative;
  top: -4px;
}

.circle_bg .erc_img,
.circle_bg .bep_img {
  height: 15px;
  position: relative;
  top: -4px;
  filter: brightness(1.5);
  left: 1px;
}

.circle_bg .tron_img {
  height: 12px;
  position: relative;
  top: -2px;
  filter: brightness(1.5);
  left: 1px;
}

/*Footer with waves*/
.collateral_ratio {
  padding: 3.075rem 2.375rem 5rem;
  background-repeat: no-repeat;
  -webkit-backdrop-filter: blur(3.125rem);
  backdrop-filter: blur(3.125rem);
  border-radius: 1.25rem;
  border: 1px solid #5b5b5b57 !important;
  position: relative;
  background-color: #00000094;

}

.ratio_card {
  border: 1px 1px solid rgb(42 29 64);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: rgb(135 139 209 / 33%);
  /* margin-top: 1.5rem; */

}

.footer-wave {
  position: relative;
  background: #141041;
  height: 155px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  top: 9rem;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  /* border: 1px solid  rgb(135 139 209 / 33%); */

}

.finance_img {
  width: 82% !important;
}

.finance_head {
  font-size: 1.1rem;
  font-weight: normal;
  margin-bottom: 0rem;
  /* line-height: 33px; */
  text-align: center;
  margin-bottom: 0.7rem;
}



.social-icon,
.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
  list-style: none;
}

.social-icon__link {
  font-size: 2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}

.social-icon__link:hover {
  transform: translateY(-10px);
}

.menu__link {
  font-size: 1.2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}

.menu__link:hover {
  color: white;
}

.menu__link:hover {
  opacity: 1;
}

.footer-wave p {
  color: #fff;
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 300;
}

.wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(././img/wave.png);
  background-size: 1000px 100px;
}

.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWaves 6s linear infinite;
}

.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 6s linear infinite !important;
}

.wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 6s linear infinite;
}

.wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 4s linear infinite;
}



@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }

  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }

  100% {
    background-positon-x: 0px;
  }
}





@media screen and (max-width: 480px) {

  .wallet-info,
  .formStyle,
  .token-info,
  .token-info {
    font-size: 0.8rem;
  }

  .flex_footer {
    display: grid;
    gap: 1rem;
    margin: 2rem auto;
  }
  .flex_footer > div:not(:last-child) {
    border-right:0;
    padding-right: 0 
  }

  .heading {
    font-size: 1.8rem !important;

  }

  .spacer {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }

  .page-content {
    padding-top: 4rem !important;
    padding-bottom: 0.2rem !important;
  }

  .finance_img {
    width: 100% !important;
  }

  .footer-wave {
    height: 68px !important;
    top: 7.3rem !important;
  }

  .mob_nav {
    display: grid !important;

  }

  .mob-none {
    display: none;
  }

  .minHeightcard {
    height: 195px;
  }

  .ratio_card_order-1 {
    order: 1
  }

  .ratio_card_order-2 {
    order: 2
  }

}

@media screen and (min-width: 481px) and (max-width: 640px) {
  .minHeightcard {
    height: 368px;
  }

  .finance_img {
    width: 100% !important;
  }


}


@media screen and (min-width: 641px) and (max-width:768px) {

  .minHeightcard {
    height: 300px;
  }

  .footer-wave {
    height: 115px;
  }

  .ratio_card_order-1 {
    order: 1
  }

  .ratio_card_order-2 {
    order: 2
  }

  .finance_img {
    width: 100% !important;
  }
}


@media screen and (min-width: 769px) and (max-width:992px) {

  .minHeightcard {
    height: 275px;
  }

  .footer-wave {
    height: 100px;
  }

  .finance_img {
    width: 100% !important;
  }

}

@media screen and (min-width: 993px) and (max-width:1198px) {

  .footer-wave {
    height: 115px;
  }

  .ratio_card {
    margin-top: 1rem !important;
  }
}

@media screen and (min-width: 1025px) and (max-width:1399px) {

  /* .minHeightcard {
    height:205px;   
  } */
  .footer-wave {
    height: 108px;
  }

}


@media screen and (max-width :992px) {
  .mobile_offcanvas_body {
    background-color: #0f101f !important;
    color: #fff;
  }

}




/* CSS */
.button-63 {
  align-items: center;
  background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  /* max-width: 100%;
  min-width: 140px; */
  padding: 19px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-63:active,
.button-63:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-63 {
    font-size: 24px;
    min-width: 196px;
  }
  .padding_p4 {
    padding: 2rem;
  }
}

.page-content {
  padding-top: 6rem;
  padding-bottom: 4rem;
  min-height: 90vh;
}

.section-container-lg {
  margin-bottom: 0;
  margin-top: 0;
  max-width: 1064px;
  position: relative;
  z-index: 2;
}

.div-block-377 {
  background-color: #140f2c;
  padding: 4rem 2rem;
  width: 100%;
}

.us.cta {
  background-color: #020314;
}

/* .image_bg {
  background-color: transparent;
  background-image: url(https://cdn.prod.website-files.com/609486d2fafa37698a57db5b/646dcb8fb0ba0c6aee77a44c_c-gradient-layer-blur.svg);
  background-position: 50% 0;
  background-size: 1066px;
  background-repeat: no-repeat;
} */

.token-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wallet-info {
    /* font-size: 0.875rem; */
    font-weight: 500;
    color: hsla(0, 0%, 100%, 0.6);
}

.token-max {
    font-weight: 500;
    font-size: 0.875rem;
    color: #82ffe1;
    margin-left: 0.3125rem;
    cursor: pointer;
    opacity: 0.7;
}
.token-logos {
  width:30px;
  margin-right: 5px;
  /* height: 40px; */
}

.convert-arrow-bg {
  height:40px;
  width: 40px;
  cursor: pointer;
 
}


.formStyle .form-control,
.formStyle .form-control:focus,
.formStyle .form-control:disabled,
.formStyle .form-control[readonly] {
  background-color: #312E42;  
  border: 1px solid #312E42;
  box-shadow: none;
  height: 50px;
}

.formStyle .input-group-text {
  background-color: #312E42;
  border-color: #312E42;
  color: #5b6267;
  font-size: 14px;
}

