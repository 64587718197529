.voting-bg {
    background-image: linear-gradient(177deg, rgb(0 182 191 / 0%), rgb(15 7 30 / 45%) 86.61%), linear-gradient(360deg, #18134dc4, #0f071e);
}

.rounded-15 {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}

.text-vote {
    color: #6e7bae;
}

.text-design {
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
    font-size: 16px;
    font-weight: 900;
}

.text-date {
    /* font-family: Arial, Helvetica, sans-serif; */
}

.btn-border {
    cursor: pointer;
    padding: 1px 12px 1px 12px;
    border: 1px solid #ed4b9e;
    color: #ed4b9e;
    border-radius: 15px;
}

.btn-set-green {
    cursor: pointer;
    padding: 3px 12px 3px 12px;
    /* border: 1px solid #6e7bae; */
    background: #27a98a;
    color: white;
    border-radius: 15px;
}

.btn-set-warning {
    cursor: pointer;
    padding: 3px 12px 3px 12px;
    /* border: 1px solid #6e7bae; */
    background: #f0b90b;
    color: white;
    border-radius: 15px;
}

.btn-set-closed {
    cursor: pointer;
    padding: 3px 12px 3px 12px;
    /* border: 1px solid #6e7bae; */
    background: rgb(80, 80, 80);
    color: white;
    border-radius: 15px;
}

.Voting-border {
    /* min-height: 300px; */
    padding: 1.2px;
    border-radius: 5px;
    background-image: linear-gradient(var(--gradient-rotate, 246deg), #d82eef59 7.97%, #2b6bffa6 49.17%, #39d0d8 92.1%);
}

.voting-card-bg {
    border-radius: 5px;
    background-image: linear-gradient(140.14deg, rgba(0, 182, 191, .15), rgba(27, 22, 89, .1) 86.61%), linear-gradient(321.82deg, #18134dab, #1b1659);
}

.border-line {
    padding-bottom: 2px;
    background-image: linear-gradient(var(--gradient-rotate, 246deg), #da2eef 7.97%, #2b6aff 49.17%, #39d0d8 92.1%);
}

.bg-cardN {
    background-image: linear-gradient(177deg, rgb(0 182 191 / 0%), rgb(15 7 30 / 45%) 86.61%), linear-gradient(360deg, #18134dc4, #0f071e);
}

.bg-tab-head {
    background: #141041;
    /* background-image: linear-gradient(177deg,rgb(0 182 191 / 0%),rgb(15 7 30 / 45%) 86.61%),linear-gradient(360deg,#18134dc4,#0f071e); */
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
}

.border-top-LR {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.bg-ratio {
    border-top: 1px solid #6e7bae;
    /* background: #191f59; */
    /* background-image: linear-gradient(177deg,rgb(0 182 191 / 0%),rgb(15 7 30 / 45%) 86.61%),linear-gradient(360deg,#18134dc4,#0f071e); */
}

.bottom-border {
    border-bottom: 1px solid #6e7bae;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: #1f192a;
    cursor: pointer;
    margin: 10px;
    border: 0px solid transparent !important;
    border-radius: 0.25rem;
}


.nav-link {
    color: #f6f7f3 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #ffffff !important;
    background: linear-gradient(to right, #6253e1, #9C27B0);
    border-color: #dee2e6 #dee2e6 #fff;
}


.form-check-input {
    width: 1.2em;
    height: 1.2em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1pxsolidrgba(0, 0, 0, .25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.padding-lable {
    padding-top: 3px;
}

@media only screen and (max-width: 992px) {
    .connect-wallet {
        font-size: 12px;
        margin-top: -12px;
        line-height: 1.8rem;
    }
}

.hoverclick :hover {
    background-color: #141041;
}

.icon {
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    color: #8884b9;
}