.progressn {
    width: auto !important;
    background-color: #3b394b;
    border-radius: 20px;
    /* margin: auto; */
    padding-left: 0px;
    height: 12px;
}
.border-all {
    border: 1px solid #6e7bae;
}
.rounded-15-all{
    border-radius: 15px;
}
.progress-bar1 {
    font-weight: 600 !important;
    background-image: linear-gradient(to right, rgb(76, 175, 169) 0%, rgb(168, 121, 251) 100%) !important;
    /* background-image: linear-gradient(135deg,#8a2387,#e94057 50%,#f27121) !important; */
}
.progress1 {
    /* width: 60%; */
    background-color: #3b394b;
    border-radius: 20px;
    margin: auto;
    padding-left: 0px;
    height: 12px;
    display: flex;
    /* height: 1rem; */
    overflow: hidden;
    font-size: .75rem;
    /* background-color: #e9ecef; */
    /* border-radius: 0.25rem; */
}
.progress-bar1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0d6efd;
    transition: width .6s ease;
}