.stablecoin-intro {
  margin: 10.775rem auto 6.125rem
}

.stablecoin-img-container .stablecoin-img {
  width: 100%;
}

.card-ban {
  border: 1px solid rgba(255, 255, 255, .06);
  border-radius: 1em;
  /* background-color: #060217; */
  background-color: #110a1a;
  /* box-shadow: 1px 1px 20px 0 hsl(0deg 0% 100% / 12%); */
  min-height: 205px;
}

.bar-ban {
  font-size: 1rem !important;
}

.card-ban-text {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  color: #ededef;
  font-size: 1.36rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: -.025em;
}

@media only screen and (min-width: 769px) {
  .card-ban {
    min-height: 291px !important;
  }
}

@media only screen and (min-width: 992px) {
  .card-ban {
    min-height: 236px !important;
  }
}

@media only screen and (max-width: 978px) {
  .stablecoin-intro {
    margin: 12.375rem auto 6.125rem
  }
}

@media only screen and (min-width: 1200px) {
  .card-ban {
    min-height: 202px !important;
  }
}


.stablecoin-para {
  padding-left: 10px;
  padding-right: 10px;
  font-style: normal;
  font-size: .81rem;
  /* line-height: 1.65rem;
  letter-spacing: .02em;
  text-align: left; */
  color: #a09fa6;
}

@media only screen and (max-width: 978px) {
  .stablecoinChart .stablecoin-introduction-main .item p {
    max-width: 100%;
    font-weight: 400;
    font-size: .6125rem;
    line-height: 1.375rem;
  }
}

.usdd-exchange {

  margin: 5.75rem auto 2.3125rem;
}

.usdd-exchange .usdd-cont {
  padding: 2.375rem 0 1.375rem 5.625rem;
  background-repeat: no-repeat;
  -webkit-backdrop-filter: blur(3.125rem);
  backdrop-filter: blur(3.125rem);
  border-radius: 1.25rem;
  border: 1px solid #5b5b5b57 !important;
  /* background-image: url("../img/bg/bg-Current Price Stability.png"); */
  /* background-image: linear-gradient(135deg,#8a2387,#e94057 50%,#f27121); */
  /* background-image: url("/src/img//bg/top-ban.svg"); */
  /* background-size: 100% 100%; */
  position: relative;
  /* background-position-y: 70%; */
  /* background-position-x: 90%; */
  box-shadow: 1px 1px 20px 0 hsl(0deg 0% 100% / 12%);
  background-color: #00000030;
}

.usdd-exchange .usdd-cont .title {
  font-weight: 600;
  font-size: 2rem;
  line-height: 108.6%;
  letter-spacing: .02em;
  color: #fff;
}

.usdd-exchange .usdd-cont .desc {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5625rem;
  letter-spacing: .02em;
  /* color: hsla(0, 0%, 100%, .7); */
  margin: 1.275rem 0 3.75rem;
  max-width: 37.875rem;
}

.usdd-exchange .usdd-cont .exchange {
  border: none;
  /* background: linear-gradient(90deg, #4cafa9 17.4%, rgba(168, 121, 251, .81) 89.41%); */
  background: linear-gradient(135deg, #8a2387, #e94057 50%, #f27121);
  border-radius: 0.625rem;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  min-width: 12rem;
  padding: 0 1.875rem;
  height: 3.125rem;
  line-height: 3.125rem;
  margin: 0 0.625rem 0 0;
}

.usdd-exchange .usdd-cont .exchangeRight {
  display: inline-block;
  width: 30.8125rem;
  height: 26.1875rem;
  position: absolute;
  right: -0.5625rem;
  top: -3.875rem;
}

@media only screen and (max-width: 978px) {
  .usdd-exchange {
    padding: 0 0.9375rem;
    margin: 5rem auto 1.25rem;
  }
}

@media only screen and (max-width: 978px) {
  .usdd-exchange .usdd-cont {
    padding: 2.5rem 0.9375rem 1.5rem;
    border-radius: 0.625rem;
    /* background-image: url("/public/img/download\ \(1\).png"); */
  }
}

/* @media only screen and (max-width: 978px) {
  .usdd-exchange .usdd-cont .title {
    font-size: 1rem;
  }

} */

@media only screen and (max-width: 978px) {
  .usdd-exchange .usdd-cont .desc {
    font-size: .875rem;
    line-height: 1.25rem;
    margin: 0.9375rem 0 2.5rem;
    max-width: auto;
  }
}

@media only screen and (max-width: 978px) {
  .usdd-exchange .usdd-cont {
    padding: 2.5rem 0.9375rem 1.5rem;
    border-radius: 0.625rem;
    /* background-image: url("/public/img/download\ \(1\).png"); */
  }
}

@media only screen and (max-width: 978px) {
  .usdd-exchange .usdd-cont .usdd-btn {
    text-align: center;
  }

}

@media only screen and (max-width: 978px) {
  .usdd-exchange .usdd-cont .exchange {
    border-radius: 0.375rem;
    min-width: 6.25rem;
    padding: 0 0.625rem;
    height: 1.5rem;
    line-height: 1.5rem;
    margin: 0 auto;
  }



}

@media only screen and (max-width: 992px) {
  .usdd-exchange .usdd-cont .exchangeRight {
    width: 9.9375rem;
    height: 8.1875rem;
    right: 0.5625rem;
    top: -3.25rem;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1199px) {
  .usdd-exchange .usdd-cont .exchangeRight {
    width: 16.9375rem;
    height: 14.1875rem;
    right: 0.5625rem;
    top: 53px;
}
}



.stablecoin-chartWrapper {
  background: linear-gradient(180deg, #232625, rgba(35, 38, 37, .1));
  border-radius: 1.25rem;
  padding: 1.875rem;
}

.bar-chart {
  /* position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  width: 550px;*/
  height: 300px;
  display: flex;
  color: #fff;
}

.skill {
  position: relative;
  flex: 1;
  text-align: center;
}

.graph {
  position: absolute;
  width: 30px;
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.graph:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  top: 2px;
  left: 2px;
  right: 2px;
  background: linear-gradient(0deg, #041c22, #86e6f5);
  box-shadow: 0px -4px 10px #56b3ad;
  border-radius: 10px;

}

/* .graph:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  background: rgba(255, 255, 255, 0.1);
} */
.name {
  position: absolute;
  bottom: -55px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  padding: 3px 8px;
}

.percentage {
  position: absolute;
  top: -20px;
  text-align: center;
  left: 0;
  right: 0;
  font-size: 15px;
  font-weight: 700;
}

.supportingInstitutions-list .item:hover span {
  -webkit-transform: scale(.78);
  transform: scale(.78);
}

.supportingInstitutions-list .item:hover .text-wrapper {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}