.nav-tabs > li > a img {
    opacity: 0.3;
    width: 24px;
    cursor: pointer;
}
.nav-tabs > li > a.active img{
    cursor: pointer;
    opacity: 1;
}

.nav-tabs > li > a.active, .nav-tabs > li > a.active:hover, .nav-tabs > li > a.active:focus {
    background-color: transparent;
    border-color: transparent;
    border: 0;
}
.nav-tabs > li > a.active:focus{
    border: 0!important;
}
.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: transparent;
    border:0;
}
.nav-tabs > li > a:hover {
    border-color: transparent;
    border:0;
    /* border-bottom: 1px solid #66dddd !important; */
}
.nav-tabs > li > a:hover img {
    opacity: 1;
}
a:focus {
    outline: none;
}
/* .nav-tabs > li > a.active{
    border-bottom: 1px solid #66dddd !important;
} */
.nav-tabs {
    border-bottom: 1px solid rgb(255,255,255,0.1);
}
#our_token_area .nav-tabs > li{
    width: 8.33%;
}

